const set = function (key: string, value: string): void {
  console.log('Set ' + key + ' - Local Secure Storage')
  return window.localStorage.setItem(key, value)
}

const get = function (key: string): any {
  console.log('Get ' + key + ' - Local Secure Storage')
  return window.localStorage.getItem(key)
}

const clear = function (): void {
  console.log('Clear All - Local Secure Storage')
  window.localStorage.clear()
}

export default { set, get, clear }
export { set, get, clear }
