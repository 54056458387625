



























import { EquipmentType } from '@/api/SwaggerClient'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { createNamespacedHelpers } from 'vuex'
const { mapGetters, mapActions } = createNamespacedHelpers('equipment')

  @Component({
    name: 'EquipmentRow',
    methods: {
      ...mapActions({
        setSelectedEquipment: 'setSelectedEquipment',
        setSidebarEquipmentList: 'setSidebarEquipmentList'
      })
    },
    computed: {
      ...mapGetters({
        selectedEquipment: 'selectedEquipment'
      })
    }
  })
export default class EquipmentRow extends Vue {
  setSelectedEquipment!: (equipment: EquipmentType) => void
  setSidebarEquipmentList!: (equipmentList: EquipmentType[]) => void

  selectedEquipment!: EquipmentType | null

  @Prop(Object)
  equipment!: EquipmentType

  navigateToChildrenEquipmentAndSelectFirstChild (equipment: EquipmentType) {
    if (this.$isWeb && equipment.childrenEquipment?.length) {
      this.setSelectedEquipment(equipment) // must set parent as selected first so its in the equipment list to be available when navigating back
      this.setSidebarEquipmentList(equipment.childrenEquipment)
      this.setSelectedEquipment(equipment.childrenEquipment[0])
    }
  }
}
