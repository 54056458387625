




























































import { Component, Prop, Vue } from 'vue-property-decorator'
import { EquipmentType } from '@/api/SwaggerClient'
import { AlertType } from '@/services/alerts/alert-options.type'
import { createNamespacedHelpers } from 'vuex'
import AddEquipmentForm from '@/components/AddEquipmentForm.vue'
//! Use the "type" keyword to ONLY import the types (if you import the whole lib, it crashes the build whilst looking for some keyframe-animation library)
import type { confirm } from '@nativescript/core'
import DeleteModal from '@/components/ConfirmModal.vue'

const { mapActions } = createNamespacedHelpers('equipment')
const { mapMutations: mapMutationsUi } = createNamespacedHelpers('ui')
const { mapGetters } = createNamespacedHelpers('equipment')
const { VUE_APP_MODE } = process.env

@Component({
  name: 'EquipmentDetailsHeader',
  components: {
    DeleteModal
  },
  methods: {
    ...mapActions({
      deleteEquipment: 'deleteEquipment',
      loadAllEquipment: 'loadAllEquipment',
      setSelectedEquipment: 'setSelectedEquipment',
      goBackInEquipmentPath: 'goBackInEquipmentPath'
    }),
    ...mapMutationsUi({
      setEquipmentModalDisplay: 'setEquipmentModalDisplay',
      setEquipmentModalId: 'setEquipmentModalId'
    })
  },
  computed: {
    ...mapGetters({
      allEquipmentTree: 'allEquipmentTree'
    })
  }
})
export default class EquipmentDetailsHeader extends Vue {
  @Prop(Object) equipment!: EquipmentType
  private isWeb = VUE_APP_MODE === 'web'
  private showDeleteModal = false
  setMaintenanceModalDisplay!: (val: boolean) => void
  deleteEquipment!: (equipmentId: number) => Promise<boolean>
  loadAllEquipment!: () => Promise<void>
  setSelectedEquipment!: (equipment: EquipmentType) => void
  allEquipmentTree!: EquipmentType[]
  setEquipmentModalDisplay!: (val: boolean) => void
  setEquipmentModalId!: (val: number) => void
  goBackInEquipmentPath!: () => void

  showEditModal () {
    this.setEquipmentModalDisplay(true)
    this.setEquipmentModalId(this.equipment.id)

    if (VUE_APP_MODE === 'native') {
      this.$showModal(AddEquipmentForm, { fullscreen: true, props: { selectedEquipment: this.equipment, editMode: true } })
    }
  }

  async deleteItem () : void {
    const deleted: boolean = await this.deleteEquipment(this.equipment.id)
    if (deleted) {
      this.$notify({ text: 'Equipment deleted', type: AlertType.INFO })
      await this.loadAllEquipment()
    } else {
      this.$notify({ text: 'Error while deleting equipment', type: AlertType.ERROR })
    }
  }

  showDeleteEquipmentModal (): void {
    if (this.$isWeb) {
      this.showDeleteModal = true
      return
    }
    confirm({
      title: 'Confirm Delete',
      message: `Are you sure you want to delete ${this.equipment.name} #${this.equipment.serialNumber}?`,
      okButtonText: 'Delete',
      cancelButtonText: 'Cancel'
    }).then(async (pressedDelete) => {
      if (pressedDelete) {
        this.deleteItem()
      }
    })
  }
}
