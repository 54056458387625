<template>
  <v-select
    :class="`formulate-input-element style-chooser formulate-input-element--${context.type}`"
    :data-type="context.type"
    v-model="context.model"
    @input="(value) => context.rootEmit('input', value)"
    :options="context.options"
    v-bind="context.attributes"

    :label="context.attributes.searchLabel"
    @close="onClose"
  />
</template>

<script>
import vSelect from 'vue-select'
export default {
  components: {
    'v-select': vSelect
  },
  props: {
    context: {
      type: Object,
      required: true
    }
  },
  methods: {
    onClose: function () {
      this.context.blurHandler()
    }
  }
}
</script>

<style scoped>
>>> {
  --vs-line-height: 1.2;
  --vs-border-color: rgb(156 163 175);
  --vs-border-width: 1px
}
</style>
