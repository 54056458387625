































































































import { Component, Vue } from 'vue-property-decorator'
import { createNamespacedHelpers } from 'vuex'
import formatDateToHTML from '@/utils/formatDateToHTML'
import XDateInput from '@/components/XDateInput.vue'
import EquipmentLogForm from '@/components/EquipmentLogForm.vue'
import FilterSidePanel from '@/components/SidePanel/FilterSidePanel.vue'
import SidePanel from '@/components/SidePanel/SidePanel.vue'
const { VUE_APP_MODE } = process.env
const { mapActions, mapGetters } = createNamespacedHelpers('calendar')
const { mapMutations: mapMutationsUi } = createNamespacedHelpers('ui')

@Component({
  name: 'LogSchedulePageTopbar',
  components: {
    XDateInput,
    FilterSidePanel,
    SidePanel
  },
  methods: {
    ...mapActions({
      setSelectedDate: 'setSelectedDate'
    }),
    ...mapMutationsUi({
      setCreateMaintenanceModalDisplay: 'setCreateMaintenanceModalDisplay'
    })
  },
  computed: {
    ...mapGetters({
      selectedDate: 'selectedDate',
      startOfWeekDate: 'startOfWeekDate',
      endOfWeekDate: 'endOfWeekDate'
    })
  }
})
export default class LogSchedulePageTopbar extends Vue {
  VUE_APP_MODE?: string = VUE_APP_MODE
  setSelectedDate!: (newDate: string) => void
  selectedDate!: string
  startOfWeekDate!: string
  endOfWeekDate!: string
  setCreateMaintenanceModalDisplay!: (val: boolean) => void
  private filterPanelOpen = false
  private modalFilterOptions = null

  openFilterPanel () {
    this.$showModal(FilterSidePanel, { fullscreen: true, props: { modalFilterOptions: this.modalFilterOptions } }).then((filterOptions) => {
      if (filterOptions.status) {
        this.modalFilterOptions = filterOptions
        this.$emit('apply-filter', filterOptions)
      }
    })
  }

  created () {
    this.setDateToday()
  }

  setDateToday () {
    this.setSelectedDate(formatDateToHTML(new Date()))
  }

  nextWeek () {
    this.selectedDateModel = formatDateToHTML(
      new Date(
        new Date(this.selectedDate).setDate(
          new Date(this.selectedDate).getDate() + 7
        )
      )
    )
  }

  previousWeek () {
    this.selectedDateModel = formatDateToHTML(
      new Date(
        new Date(this.selectedDate).setDate(
          new Date(this.selectedDate).getDate() - 7
        )
      )
    )
  }

  showAddLogModal () {
    this.setCreateMaintenanceModalDisplay(true)
    if (!this.$isWeb) {
      this.$showModal(EquipmentLogForm, {
        fullscreen: true,
        props: { mode: 'create' }
      })
    }
  }

  get selectedDateModel () {
    return this.selectedDate
  }

  set selectedDateModel (newDate: string) {
    this.setSelectedDate(newDate)
  }
}
