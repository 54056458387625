import formatDateToHTML from '@/utils/formatDateToHTML'
import calculateWeekRange from '@/utils/calculateWeekRange'

export const calendar = {
  namespaced: true,
  state: {
    selectedDate: formatDateToHTML(new Date()),
    startOfWeekDate: null,
    endOfWeekDate: null
  },
  mutations: {
    SET_SELECTED_DATE (state, selectedDate) {
      state.selectedDate = selectedDate
    },
    SET_WEEK_RANGE (state, { startOfWeekDate, endOfWeekDate }): void {
      state.startOfWeekDate = startOfWeekDate
      state.endOfWeekDate = endOfWeekDate
    }
  },
  actions: {
    async setSelectedDate ({ commit, dispatch }, selectedDate: string) {
      commit('SET_SELECTED_DATE', selectedDate)
      const weekRange = calculateWeekRange(selectedDate)
      commit('SET_WEEK_RANGE', weekRange)
      await dispatch('maintenanceLogs/loadSelectedWeekLogs', weekRange, {
        root: true
      })
    }
  },
  getters: {
    selectedDate (state): string {
      return state.selectedDate
    },
    startOfWeekDate (state): string {
      return state.startOfWeekDate
    },
    endOfWeekDate (state): string {
      return state.endOfWeekDate
    }
  }
}
