











import Vue from 'vue'

export default Vue.extend({
  props: {
    image: {
      type: String,
      required: true
    }
  },
  methods: {
    close () {
      this.$modal.close()
    }
  }
})

