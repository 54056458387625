









import { Component, Vue, Prop } from 'vue-property-decorator'
  @Component({
    name: 'ErrorMessage',
    components: {
    }
  })
export default class Login extends Vue {
  @Prop(String) private msg!:string;
}
