








import { Component, Vue, Prop } from 'vue-property-decorator'
  @Component({
    name: 'FormEntry',
    components: {
    }
  })
export default class Login extends Vue {
  @Prop(String) private title!:string;
  @Prop(String) private details!:string;
  @Prop(String) private value!:string;
}
