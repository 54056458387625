

















import { Component, Vue, Prop } from 'vue-property-decorator'
import EquipmentRow from '@/views/EquipmentListPage/Sidebar/EquipmentList/EquipmentRow.vue'
import { EquipmentType } from '@/api/SwaggerClient'
import { createNamespacedHelpers } from 'vuex'
const { mapGetters, mapActions, mapState } = createNamespacedHelpers('equipment')

  @Component({
    name: 'EquipmentList',
    components: {
      EquipmentRow
    },
    methods: {
      ...mapActions({
        setSelectedEquipment: 'setSelectedEquipment',
        setSidebarEquipmentList: 'setSidebarEquipmentList'
      })
    },
    computed: {
      ...mapGetters({
        selectedEquipment: 'selectedEquipment'
      }),
      ...mapState({
        sidebarEquipmentList: 'sidebarEquipmentList'
      })
    }
  })
export default class EquipmentList extends Vue {
  @Prop(Array)
  equipmentList!: EquipmentType[]

  setSelectedEquipment!: (equipment: EquipmentType) => void
  setSidebarEquipmentList!: (equipmentList: EquipmentType[]) => void

  sidebarEquipmentList!: EquipmentType[]

  selectedEquipment!: EquipmentType | null

  selectEquipmentRow (equipment: EquipmentType) {
    this.setSelectedEquipment(equipment)
    if (!this.$isWeb) {
      if (equipment.childrenEquipment?.length) {
        this.setSidebarEquipmentList(equipment.childrenEquipment)
      } else {
        this.setSidebarEquipmentList([])
      }
    }
  }
}
