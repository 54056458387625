





import { Component, Vue, Prop, VModel } from 'vue-property-decorator'
import DateModal from '@/components/DateModal.vue'
const { VUE_APP_MODE, VUE_APP_PLATFORM } = process.env

@Component({
  name: 'XDateInput'
})
export default class XDateInput extends Vue {
  @VModel({ type: String }) innerValue!: string
  private isIOS = VUE_APP_PLATFORM === 'ios'

  openDateModal () {
    this.$showModal(DateModal, { props: { initialDateValue: this.innerValue } }).then((date) => {
      // date.setUTCHours(12, 0o0, 0o0)
      const tzOffset = date.getTimezoneOffset() * 60000 // offset in milliseconds
      const localISOTime = (new Date(date - tzOffset)).toISOString().split('T')[0]

      this.innerValue = localISOTime
      this.$emit('input', localISOTime)
    })
  }
}
