





import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import Vue from 'vue'
Vue.use(VueViewer)

export default {
  props: {
    images: Array
  },
  methods: {
    show () : void {
      this.$viewerApi({
        images: this.images as Array<string>
      })
    }
  }
}
