import Vue from 'vue'
import App from './App.vue'
import store from '@/store'
import { webRouter } from './services/router/router.web'
import { RawLocation, Route } from 'vue-router'
import 'nativescript-tailwind/dist/tailwind.css'
import {
  Page,
  ActionBar,
  GridLayout,
  ScrollView,
  Button,
  Img,
  Label,
  Span,
  TextField,
  SearchBar,
  AbsoluteLayout,
  FlexboxLayout,
  StackLayout,
  TextView,
  DatePicker,
  Nvw
} from 'nativescript-vue-web'
// import 'nativescript-tailwind/dist/tailwind.css'
import '@/assets/output.css'
import VueFormulate from '@braid/vue-formulate'
import FormulateVSelectPlugin from '@/formulate_plugins/vSelect'
import FormulateNSTextFieldPlugin from '@/formulate_plugins/nsTextField'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import NSFormError from '@/formulate_plugins/NSFormError.vue'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import { notify } from '@/services/alerts/alerts.web'

Vue.use(Toast, { timeout: 3000 })
Vue.use(Nvw)
Vue.component('v-select', vSelect)

Vue.component('NSFormError', NSFormError)
Vue.use(VueFormulate, {
  plugins: [FormulateVSelectPlugin, FormulateNSTextFieldPlugin],
  validationNameStrategy: ['validationName', 'label', 'name', 'type'],
  slotComponents: {
    errors: 'NSFormError'
  }
})
Vue.component('Page', Page)
Vue.component('ActionBar', ActionBar)
Vue.component('GridLayout', GridLayout)
Vue.component('Button', Button)
Vue.component('Img', Img)
Vue.component('Label', Label)
Vue.component('Span', Span)
Vue.component('TextField', TextField)
Vue.component('SearchBar', SearchBar)
Vue.component('DatePicker', DatePicker)
Vue.component('FlexboxLayout', FlexboxLayout)
Vue.component('StackLayout', StackLayout)
Vue.component('AbsoluteLayout', AbsoluteLayout)
Vue.component('DatePicker', DatePicker)
Vue.component('ScrollView', ScrollView)
Vue.component('TextView', TextView)

Vue.config.productionTip = false
Vue.prototype.$isWeb = true
Vue.prototype.$goto = function (to, options?: RawLocation) {
  // console.log('Going to: ', to)
  if (options) {
    const routerOptions: RawLocation = Object.assign({}, options, { [to.includes('/') ? 'path' : 'name']: to })
    webRouter.push(routerOptions)
  } else {
    webRouter.push(to)
  }
}

Vue.prototype.$routerr = webRouter
Vue.prototype.$notify = notify

export const vm = new Vue({
  router: webRouter,
  store,
  render: (h) => h(App)
}).$mount('#app')
