








import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import EquipmentDetailsHeader from '@/views/EquipmentListPage/EquipmentDetails/EquipmentDetailsHeader.vue'
import MaintenanceLogsTable from '@/views/EquipmentListPage/EquipmentDetails/MaintenanceLogsTable.vue'
import { EquipmentType, MaintenanceLogType, PaginatedDtoType } from '@/api/SwaggerClient'
@Component({
  name: 'EquipmentDetails',
  components: {
    EquipmentDetailsHeader,
    MaintenanceLogsTable
  }
})
export default class EquipmentDetails extends Vue {
  @Prop(Object)
  equipment!: EquipmentType
}
