








import { AuthUserType } from '@/api/SwaggerClient'
import { getGravatarImageUrl, GravatarTypes } from '@/services/ui'
// import { getGravatarImageUrl } from '@/services/ui'
import { Component, Vue, Prop } from 'vue-property-decorator'
  @Component({
    name: 'Assignee',
    components: {
    }
  })
export default class Assignee extends Vue {
  @Prop() private user!: AuthUserType;
  profilePictureUrl = getGravatarImageUrl(this.user.email || 'example@example.com', GravatarTypes.retro).toString()

  deleteItem () : void {
    this.$emit('deleteItem')
  }
}
