














import { Component, Vue, Prop } from 'vue-property-decorator'

  @Component({
    name: 'SearchField',
    components: {
    }
  })
export default class SearchField extends Vue {
  @Prop(Array) private options!:Array<any>;
  @Prop(String) private label!:string;
  @Prop(String) private placeholderText!:string;
  private input = ''

  get filteredOptions () {
    let output = this.options
    if (this.input) {
      output = output.filter((option) => option.name.toLowerCase().includes(this.input.toLowerCase()))
    }
    return output
  }

  onItemTap (event) : void {
    this.$modal.close(event.item)
  }
}
