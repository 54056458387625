













import { Component, Vue } from 'vue-property-decorator'
import Login from '@/components/Login.vue'

  @Component({
    name: 'LogoutPage',
    components: {
      Login
    }
  })
export default class LogoutPage extends Vue {
}

