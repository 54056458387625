





































import { Component, Vue, Prop } from 'vue-property-decorator'
import TextFieldWithIcon from '@/components/TextFieldWithIcon.vue'
import SearchResult from '@/components/SearchResult.vue'
import XCheckBox from '@/components/XCheckBox.vue'
import { routes } from '@/services/router/routes'
import { Page } from '@/global/types'
import store from '@/store/index'

const selectRoutes = (inputArray) => {
  const selectedRoutes: Array<Record<string, any>> = []
  for (const i in inputArray) {
    // console.log(i)
    routes.forEach((route) => {
      if (route.name === inputArray[i]) {
        selectedRoutes.push(route)
      }
    })
  }
  // console.log('Selected routes :>>', selectedRoutes)
  return selectedRoutes
}

@Component({
  name: 'NavigationSidePanel',
  components: {
    TextFieldWithIcon,
    SearchResult,
    XCheckBox
  },
  data () {
    return {
      routes
    }
  }
})
export default class NavigationSidePanel extends Vue {
  @Prop(String) private hint!:string;

  selectedRoutes = selectRoutes(['Equipment Page', 'Log Schedule']);

  closePanelAndGoto (path: string): void {
    this.$goto(path)
    this.$emit('close')
    if (this.$modal) {
      this.$modal.close()
    }
  }

  async logout () {
    store.dispatch('auth/logout')
    this.$goto('/logout')
    this.$modal.close()
  }

  get currentRoute () {
    return this.$router.currentRoute.path
  }
}
