

















import { Component, Vue } from 'vue-property-decorator'
import ForgotPassword from '@/components/ForgotPassword.vue'

  @Component({
    name: 'ForgotPasswordPage',
    components: {
      ForgotPassword
    }
  })
export default class ForgotPasswordPage extends Vue {
}

