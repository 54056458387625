


































import { MaintenanceLogType } from '@/api/SwaggerClient'
import { Component, Prop, Vue } from 'vue-property-decorator'
import EquipmentLogForm from '@/components/EquipmentLogForm.vue'
import { getStatusColour } from '@/services/ui'
import { createNamespacedHelpers } from 'vuex'
const { mapMutations } = createNamespacedHelpers('ui')

@Component({
  name: 'Table',
  components: {},
  data () {
    return {
      getStatusColour
    }
  },
  methods: {
    ...mapMutations({
      setMaintenanceModalId: 'setMaintenanceModalId',
      setMaintenanceModalDisplay: 'setMaintenanceModalDisplay'
    })
  }
})
export default class Table extends Vue {
  @Prop(Array) private data!: MaintenanceLogType[]
  setMaintenanceModalDisplay!: (val: boolean) => void
  setMaintenanceModalId!: (val: number) => void

  // showMaintenanceModal () {
  //   this.setMaintenanceModalDisplay(true)
  // }

  openResult (index: number) {
    this.setMaintenanceModalDisplay(false)
    this.setMaintenanceModalId(this.data[index].id)
    this.setMaintenanceModalDisplay(true)

    if (!this.$isWeb) {
      this.$showModal(EquipmentLogForm, { fullscreen: true })
    }
  }
}
