



















import { Component, Vue, Prop } from 'vue-property-decorator'
import FormField from '@/components/FormField.vue'
import axios from 'axios'
import { api } from '@/services/api'
import { ContentType, CreateFileDtoType, FileType } from '@/api/SwaggerClient'
import '@/components/FileUpload/file-upload.scss'
const { VUE_APP_MODE } = process.env

  @Component({
    name: 'UserList',
    components: {
      FormField
    }
  })
export default class FileUpload extends Vue {
  @Prop(String) private user!:string;
  @Prop(String) private maintenanceLogId!:string;
  private isWeb = false
  private fileReady = false
  private fileId

  mounted () : void {
    if (VUE_APP_MODE === 'web') {
      this.isWeb = true
    }
  }

  async uploadFile (file: CreateFileDtoType, progress, error, options): Promise<Response | undefined> {
    try {
      // const result = await fetch('http://localhost:3000/files', {
      //   method: 'post',
      //   body: formData,
      //   credentials: 'include'
      // })
      const result = await api.files.createOneBaseFileControllerFile({ file: file })
      progress(100) // (native fetch doesn’t support progress updates)
      this.fileReady = true
      this.fileId = result.data.id
      return result
    } catch (err) {
      console.log('CAUGHT ERROR', error)
      error('Unable to upload file')
    }
  }

  async donePressed () : Promise<void> {
    try {
      // const result = await api.post({ fileId: this.fileId }, '/files/save-file')
      this.$emit('file-added', this.fileId)
    } catch (e) {
      console.log(e)
    }
  }

  attachFile () : void {
    this.$emit('attach-file', { name: 'Example file', size: '256kb', id: 1 })
  }
}
