












import { Component, Vue, Prop } from 'vue-property-decorator'
import TextFieldWithIcon from '@/components/TextFieldWithIcon.vue'
import SearchResult from '@/components/SearchResult.vue'

  @Component({
    name: 'SidePanel',
    components: {
      TextFieldWithIcon,
      SearchResult
    }
  })

export default class Login extends Vue {
  @Prop(Boolean) private onRight!:boolean;
  @Prop(Boolean) private isOpen!:boolean;

  searchTerm = '';
  // isOpen = false;
}
