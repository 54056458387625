


















































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Table from '@/components/Table.vue'
import Select from '@/components/Select.vue'
import { EquipmentType, MaintenanceLogType, PaginatedResponseDtoType } from '@/api/SwaggerClient'
import { createNamespacedHelpers } from 'vuex'
import EquipmentLogForm from '@/components/EquipmentLogForm.vue'
import { MaintenanceLogSortOptions, PaginatedNestedEquipmentLogsRequest } from '@/global/types'
const { VUE_APP_MODE } = process.env
const { mapMutations } = createNamespacedHelpers('ui')
const { mapActions: mapActionsLogs, mapState: mapStateLogs } = createNamespacedHelpers('maintenanceLogs')
const { mapGetters: mapGettersEquipment } = createNamespacedHelpers('equipment')

@Component({
  name: 'MaintenanceLogsTable',
  components: {
    Table,
    Select
  },
  methods: {
    ...mapMutations({
      setCreateMaintenanceModalDisplay: 'setCreateMaintenanceModalDisplay'
    }),
    ...mapActionsLogs({
      loadLogsByEquipment: 'loadLogsByEquipment',
      loadEquipmentNestedLogs: 'loadEquipmentNestedLogs',
      setLogsTableLimit: 'setLogsTableLimit',
      setLogsTablePage: 'setLogsTablePage',
      setLogsTableSortBy: 'setLogsTableSortBy'
    })
  },
  computed: {
    ...mapGettersEquipment({
      selectedEquipment: 'selectedEquipment'
    }),
    ...mapStateLogs({
      logsTableLimit: 'logsTableLimit',
      logsTablePage: 'logsTablePage',
      logsTableSortBy: 'logsTableSortBy'
    })
  }
})
export default class MaintenanceLogsTable extends Vue {
  @Prop(Number) equipmentId!: number

  private isWeb = VUE_APP_MODE === 'web'
  showAddLogModal = false
  setCreateMaintenanceModalDisplay!: (val: boolean) => void
  loadEquipmentNestedLogs!: (request: PaginatedNestedEquipmentLogsRequest) => Promise<PaginatedResponseDtoType & { items: MaintenanceLogType[]}>
  setLogsTableLimit!: (limit: number) => void
  setLogsTablePage!: (page: number | undefined) => void
  setLogsTableSortBy!: (sortBy: MaintenanceLogSortOptions | undefined) => void
  logsTablePage!: number
  logsTableLimit!: number
  logsTableSortBy!: MaintenanceLogSortOptions
  selectedEquipment!: EquipmentType;
  logs: MaintenanceLogType[] = []
  maintenanceLogPaginatedResponse: PaginatedResponseDtoType & { items: MaintenanceLogType[] } | null = null

  pageFieldText = ''
  pageTextField
  header

  onPageFieldSubmitted () {
    const page = parseInt(this.pageFieldText)
    const totalPages = this.maintenanceLogPaginatedResponse?.meta.totalPages
    if (isNaN(page)) console.log('page is not a number')
    else if (totalPages && (page > totalPages || page < 1)) console.log('page number is out of bounds of total pages')
    else this.navigateToPage(page)
    this.pageFieldText = ''
    this.$refs.header.nativeView.focus()
  }

  async navigateToPage (page: number) {
    console.log('going to page ' + page)
    this.setLogsTablePage(page)
    await this.loadLogs()
  }

  getTotalItemCount (): string {
    // The Quad '0' are needed to take up space (for when the item count is loaded by network later) - Other wise the text-box is TOO SHORT for the 2 digit number
    return String(this.maintenanceLogPaginatedResponse?.meta?.totalItems || '0000')
  }

  async created () {
    await this.loadLogs()
  }

  async loadLogs () {
    this.maintenanceLogPaginatedResponse = await this.loadEquipmentNestedLogs({ equipmentId: this.selectedEquipment.id, page: this.logsTablePage, limit: this.logsTableLimit, sortBy: this.logsTableSortBy })
    this.logs = this.maintenanceLogPaginatedResponse?.items
  }

  @Watch('selectedEquipment')
  async onSelectedEquipmentChanged () {
    // Added so that the list of logs clear when selecting new equipment (can look really strange when using a slow network)
    await this.loadLogs()
  }

  @Watch('logsTableLimit')
  async onLogsTableLimitChanged () {
    await this.loadLogs()
  }

  showMaintenanceModal () {
    this.setCreateMaintenanceModalDisplay(true)
    if (!this.$isWeb) {
      this.$showModal(EquipmentLogForm, { fullscreen: true, props: { mode: 'create' } })
    }
  }

  async goToFirstPage () {
    console.log('going to first page')
    if (this.maintenanceLogPaginatedResponse?.links.first) {
      const page = this.extractPageFromQueryPath((this.maintenanceLogPaginatedResponse.links.first))
      console.log('page :>> ', page)
      this.setLogsTablePage(page)
      await this.loadLogs()
    } else {
      console.log('there is no first page')
    }
  }

  async goToLastPage () {
    console.log('going to last page')
    if (this.maintenanceLogPaginatedResponse?.links.last) {
      const page = this.extractPageFromQueryPath((this.maintenanceLogPaginatedResponse.links.last))
      if (page) this.setLogsTablePage(page)

      await this.loadLogs()
    } else {
      console.log('There is no last page.. this is the last')
    }
  }

  async goToNextPage () {
    console.log('going to next page')
    if (this.maintenanceLogPaginatedResponse?.links.next?.length) {
      const page = this.extractPageFromQueryPath((this.maintenanceLogPaginatedResponse.links.next))
      if (page) this.setLogsTablePage(page)

      await this.loadLogs()
    } else {
      console.log('There is no next page.. this is the last')
    }
  }

  async goToPreviousPage () {
    console.log('going to previous page')
    if (this.maintenanceLogPaginatedResponse?.links.previous?.length) {
      const page = this.extractPageFromQueryPath((this.maintenanceLogPaginatedResponse.links.previous))
      if (page) this.setLogsTablePage(page)

      await this.loadLogs()
    } else {
      console.log('There is no previous page.. this is the first')
    }
  }

  extractPageFromQueryPath (queryPath: string): number | undefined {
    const pageNumberString = this.getParameterByName('page', queryPath)
    if (pageNumberString && pageNumberString.length) {
      return parseInt(pageNumberString)
    }
  }

  get paginationDetails () {
    const totalItems = this.maintenanceLogPaginatedResponse?.meta.totalItems
    if (!totalItems) return ''
    let from = 1
    let to
    if (this.logsTableLimit > totalItems) {
      to = totalItems
    } else {
      from = this.logsTablePage === 1 ? 1 : ((this.logsTablePage - 1) * this.logsTableLimit) + 1
      to = this.logsTablePage * this.logsTableLimit
    }
    if (to > totalItems) to = totalItems
    return `${from}-${to} of ${totalItems} item(s)`
  }

  getParameterByName (name: string, queryString: string) {
    // eslint-disable-next-line no-useless-escape
    name = name.replace(/[\[\]]/g, '\\$&')
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
    var results = regex.exec(queryString)
    if (!results) return null
    if (!results[2]) return ''
    return decodeURIComponent(results[2].replace(/\+/g, ' '))
  }

  async sortBySelected (index: number) {
    console.log('"Sort By" Select changed to:', this.sortByOptions[index].option)
    this.setLogsTableSortBy(this.sortByOptions[index].option)
    await this.loadLogs()
  }

  amountSelected (index: number): void {
    // console.log('"Amount" Select changed to:', this.showAmountOptions[index].name)
    this.setLogsTableLimit(this.showAmountOptions[index].data)
    this.setLogsTablePage(1) // temorary fix for bug that would display and incorrect calculation for computed paginationDetails
  }

  showAmountOptions = [
    {
      name: '25',
      data: 25
    },
    {
      name: '50',
      data: 50
    },
    {
      name: '75',
      data: 75
    },
    {
      name: '100',
      data: 100
    }
  ]

  sortByOptions: { name: string, option: MaintenanceLogSortOptions }[] = [
    {
      name: 'Newest Maintenance Date First',
      option: MaintenanceLogSortOptions.NEWEST
    },
    {
      name: 'Oldest Maintenance Date First',
      option: MaintenanceLogSortOptions.OLDEST
    },
    {
      name: 'Log Title (A-Z)',
      option: MaintenanceLogSortOptions.TITLE_ASC
    },
    {
      name: 'Log Title (Z-A)',
      option: MaintenanceLogSortOptions.TITLE_DESC
    },
    {
      name: 'Highest Engine Hour First',
      option: MaintenanceLogSortOptions.ENGINE_HOURS_HIGHEST
    },
    {
      name: 'Lowest Engine Hour First',
      option: MaintenanceLogSortOptions.ENGINE_HOURS_LOWEST
    }
  ]
}
