














































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import Assignee from '@/components/Assignee.vue'
import UserList from '@/components/UserList.vue'
import FileUpload from '@/components/FileUpload/FileUpload.vue'
import FormEntry from '@/components/FormEntry.vue'
import FormField from '@/components/FormField.vue'
import ConfirmModal from '@/components/ConfirmModal.vue'
import File from '@/components/File.vue'
import store from '@/store/index'
import Gallery from '@/components/Gallery.vue'
import { api, get } from '@/services/api'
import { createNamespacedHelpers } from 'vuex'
import { AuthUserType, CreateMaintenanceLogDtoType, EquipmentType, FileType, HttpResponse, MaintenanceLogType, UpdateMaintenanceLogDtoType } from '@/api/SwaggerClient'
import { AlertType } from '@/services/alerts/alert-options.type'
import { currencies } from '@/services/currencies'
import CurrencySelector from '@/components/CurrencySelector.vue'
import ImageCarouselModal from '@/components/ImageCarouselModal.vue'
import TempImageView from '@/components/TempImageView.vue'
//! Use the "type" keyword to ONLY import the types (if you import the whole lib, it crashes the build whilst looking for some keyframe-animation library)
import type { confirm } from '@nativescript/core'

const { mapActions: logMapActions, mapGetters: logMapGetters } = createNamespacedHelpers('maintenanceLogs')
const { mapActions: equipmentMapActions, mapGetters: equipmentMapGetters } = createNamespacedHelpers('equipment')
const { mapActions: uiMapActions, mapGetters: uiMapGetters } = createNamespacedHelpers('ui')

interface MaintenanceLogTypeUi extends MaintenanceLogType {
  uiFormattedCurrency: string
  uiFormattedDuration: string
  uiFormattedExecutionDate: string
  jsExecutionDate: Date
}

  @Component({
    name: 'EquipmentLogForm',
    components: {
      Assignee,
      UserList,
      FileUpload,
      File,
      FormEntry,
      FormField,
      Gallery,
      ImageCarouselModal,
      TempImageView,
      ConfirmModal,
      CurrencySelector
    },
    methods: {
      ...equipmentMapActions({
        loadAllEquipment: 'loadAllEquipment',
        setSelectedEquipment: 'setSelectedEquipment'
      }),
      ...logMapActions({
        createNewLog: 'createNewLog',
        loadSelectedWeekLogs: 'loadSelectedWeekLogs',
        updateMaintenanceLog: 'updateMaintenanceLog',
        loadLogsByEquipment: 'loadLogsByEquipment'
      })
    },
    computed: {
      ...uiMapGetters({
        selectedMaintenanceId: 'selectedMaintenanceId'
      }),
      ...equipmentMapGetters({
        selectedEquipment: 'selectedEquipment'
      }),
      owningEquipment () {
        if (this.mode !== 'create') {
          return this.value?.equipment?.name || ''
        }
      }
    }
  })

export default class EquipmentLogForm extends Vue {
  @Prop(Object) startValue?: Record<string, unknown>
  @Prop() mode?: string
  createNewLog!: (createNewLog: CreateMaintenanceLogDtoType) => Promise<HttpResponse<MaintenanceLogType, any>>
  loadSelectedWeekLogs!: (weekRange?) => Promise<void>
  selectedEquipment!: EquipmentType
  setSelectedEquipment!: (equipment: EquipmentType) => void
  updateMaintenanceLog!: ({ logId: number, updateMaintenanceLog: UpdateMaintenanceLogDtoType }) => Promise<HttpResponse<MaintenanceLogType, any>>
  loadAllEquipment!: () => Promise<void>
  loadLogsByEquipment!: (id: number) => MaintenanceLogType[]
  selectedMaintenanceId!: number;

  private value : Record<string, any> = {}
  private equipment = []
  private userListVisible = false
  private fileModalVisible = false
  private files: FileType[] = []
  private assignees : AuthUserType[] = []
  private validInputs = {}
  private showValidation = false
  private images: Array<string> = []
  private showDeleteModal = false
  private options = [
    { text: 'To Do', value: 'To Do' },
    { text: 'Ongoing', value: 'Ongoing' },
    { text: 'Cancelled', value: 'Cancelled' },
    { text: 'Done', value: 'Done' }
  ]

  private showConfirmModal = false
  private dirty = false;
  private valuesLoaded = false
  private startingCost = null
  foundLog = false
  errorMessage = 'Searching...'

  updateCost (cost) {
    // this.updateValue('cost', cost.amount)
    this.value.cost = cost.amount
    // this.updateValue('currency', cost.currency.symbol)
    this.value.currency = cost.currency.symbol
  }

  showImageCarousel () {
    this.$showModal(ImageCarouselModal, {
      fullscreen: true,
      props: {
        images: this.images
      }
    })
  }

  showTempImageView (url) {
    this.$showModal(TempImageView, {
      fullscreen: false,
      props: {
        image: url
      }
    })
  }

  openGallery (file) {
    const index: number = this.images.findIndex((image) => file.preSignedUrl === image)
    this.images.unshift(this.images.splice(index, 1)[0])
    if (this.$refs.gallery) {
      this.$refs.gallery.show()
    }
  }

  async openFilePicker () {
    const file = await this.$openFilePicker(this.selectedMaintenanceId)
    console.log('FILES', this.files)
    this.files.push(file)
  }

  // Neeed because Vue templates don't allow for Optional Chaining: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Optional_chaining
  takeId (object: Record<string, any>, defaultVal: any):any {
    try {
      if (typeof object?.id !== 'undefined') return object.id
      else return defaultVal
    } catch (error) {
      return defaultVal
    }
  }

  updateValidation (args) {
    this.validInputs[args.name] = !args.hasErrors
  }

  validationCheck () : boolean {
    var result = true
    for (const i in this.validInputs) {
      if (this.validInputs[i] === false) {
        result = false
        break
      }
    }
    return result
  }

  async mounted (): Promise<void> {
    try {
      if (this.mode !== 'create') {
        if (this.selectedMaintenanceId !== 0) {
          this.foundLog = false
          const res = (await api.maintenanceLog.getOneBaseMaintenanceLogControllerMaintenanceLog(this.selectedMaintenanceId)).data as MaintenanceLogTypeUi
          // Note: "res" is MaintenanceLogTypeUi and NOT MaintenanceLogType (extra properties)

          const currency = currencies.find(element => element.symbol === res.currency) || { symbolOnLeft: true, symbol: res.currency || '' }
          if (currency.symbolOnLeft) res.uiFormattedCurrency = currency.symbol + (res.cost || '')
          else res.uiFormattedCurrency = (res.cost || '') + currency.symbol

          res.uiFormattedDuration = this.minuteCountToTimeString(res.duration || 0)
          res.jsExecutionDate = new Date(res.executionDate)
          res.uiFormattedExecutionDate = res.jsExecutionDate.toISOString().slice(0, 10)

          // Populate the assignees
          if (res.assignees && Array.isArray(res.assignees)) this.assignees = res.assignees

          // Populate the files
          if (res.files && Array.isArray(res.files)) this.files = res.files
          this.files.forEach((file) => {
            const fileType = file.name?.split('.')[1]
            console.log(fileType)
            if (fileType && ['png', 'jpeg', 'jpg'].includes(fileType) && file.preSignedUrl) {
              console.log('Pushing ', file, ' to Images')
              this.images.push(file.preSignedUrl)
            }
          })

          this.value = res as unknown as Record<string, unknown>
          if (!this.$isWeb) {
            this.value.status = { text: res.status, value: res.status }
          }
          this.valuesLoaded = true
          this.foundLog = true
        } else {
          this.value = this.startValue || {}
          this.foundLog = true
        }
      } else {
        this.setDefaults()
        this.value.executionDate = new Date().toISOString().split('T')[0]
        // Has to be in the following format: "2022-04-07"
        this.value.uiFormattedExecutionDate = new Date().toISOString().split('T')[0]
        this.foundLog = true
      }

      // Get latest list of equipment (for the search bar)
      this.equipment = store.getters['equipment/allEquipment']
    } catch (error) {
      console.log('Error getting log')
      console.log(error)
      this.errorMessage = 'There was an error... Please try again...'
    }
  }

  async setDefaults (): void {
    // this.updateValue('equipment', this.selectedEquipment)
    this.value.equipment = this.selectedEquipment
    this.value.status = 'To Do'
  }

  nativeDefaultEquipment () {
    if (this.value.equipment) return this.value.equipment
    return this.selectedEquipment
  }

  async updateLog () {
    if (!this.validationCheck()) {
      this.showValidation = true
      return
    }

    const status = this.$isWeb ? this.value.status : this.value.status.value

    const newLog = {
      files: this.value.files,
      equipment: this.value.equipment.id,
      assignees: this.value.assignees.map(assignee => { return { id: assignee.id } }),
      title: this.value.title,
      status: status,
      executionDate: this.value.executionDate,
      duration: this.value.duration,
      engineHours: this.value.engineHours,
      description: this.value.description,
      cost: this.value.cost,
      currency: this.value.currency
    } as UpdateMaintenanceLogDtoType
    // const res = await api.maintenanceLog.updateOneBaseMaintenanceLogControllerMaintenanceLog(this.selectedMaintenanceId, newLog)
    const res = await this.updateMaintenanceLog({ logId: this.selectedMaintenanceId, updateMaintenanceLog: newLog })
    if (res.status === 200) {
      this.$notify({ text: 'Changes saved', type: AlertType.SUCCESS })
      await this.loadSelectedWeekLogs()
      // Refresh data
      await this.loadAllEquipment()
      this.dirty = false
      this.close()
    } else {
      this.$notify({ text: 'Error, could not save changes', type: AlertType.ERROR })
    }
  }

  async deleteLog () {
    if (this.$isWeb) {
      this.showDeleteModal = true
      return
    }

    confirm({
      title: 'Confirm Delete',
      message: 'Are you sure you want to delete this log?',
      okButtonText: 'Delete',
      cancelButtonText: 'Cancel'
    }).then(async (pressedDelete) => {
      if (pressedDelete) {
        this.deleteLogConfirmed()
      }
    })
  }

  async deleteLogConfirmed () {
    const res = await api.maintenanceLog.deleteOneBaseMaintenanceLogControllerMaintenanceLog(this.selectedMaintenanceId)
    if (res.status === 200) {
      this.$notify({ text: 'Log deleted', type: AlertType.INFO })
      const selectedEquipment = Object.assign({}, this.selectedEquipment) // Needed to fool Vue into forcing an update later
      const index = selectedEquipment.maintenanceLogs.findIndex(log => log.id === this.selectedMaintenanceId)
      selectedEquipment.maintenanceLogs.splice(index, 1)
      this.setSelectedEquipment(selectedEquipment) // Update store with new log
    } else {
      this.$notify({ text: 'Error deleting log', type: AlertType.ERROR })
    }
    this.close(true)
  }

  async createLog () {
    console.log('Form Values', this.value)
    if (!this.validationCheck()) {
      this.showValidation = true
    } else {
      const data = this.value
      data.files = this.files

      if (data.equipment && isNaN(parseInt(data.equipment))) {
        data.equipment = data.equipment.id
      }
      if (!this.$isWeb) {
        data.status = data.status.value
      }

      delete data.uiFormattedDuration
      const newLog: CreateMaintenanceLogDtoType = { ...data }
      let res: HttpResponse<MaintenanceLogType, any> | null = null
      newLog.assignees = this.assignees.map(assignee => { return { id: assignee.id } })

      try {
        res = await this.createNewLog(newLog)
      } catch (e) {
        console.log(e)
      }
      if ((res && res.status === 201) || (res && res.status === 200)) {
        this.$notify({ text: 'Log added', type: AlertType.SUCCESS })

        const selectedEquipment = Object.assign({}, this.selectedEquipment) // Needed to fool Vue into forcing an update later
        selectedEquipment.maintenanceLogs.push(res.data)
        this.setSelectedEquipment(selectedEquipment) // Update store with new log

        await this.loadSelectedWeekLogs()
        this.dirty = false
        this.close()
      } else {
        this.$notify({ text: 'Error, could not add maintenance log', type: AlertType.ERROR })
      }
      this.dirty = false
      this.close()
    }
  }

  confirmCloseModal () {
    if (this.$isWeb) {
      this.showConfirmModal = true
    } else {
      confirm({
        title: 'Unsaved changes',
        message: 'Are you sure you want to close this modal?',
        okButtonText: 'Close',
        cancelButtonText: 'Stay'
      }).then(async (pressedClose) => {
        if (pressedClose) {
          this.closeModal()
        }
      })
    }
  }

  closeConfirmModal () {
    this.showConfirmModal = false
  }

  closeModal () {
    this.$emit('close')
    if (!this.$isWeb) {
      this.$modal.close()
    }
  }

  close (force: boolean) : void {
    if (this.dirty && !force) {
      this.confirmCloseModal()
    } else {
      this.closeModal()
    }
  }

  deleteItem (index) {
    this.files.splice(index, 1)
  }

  updateValue (key, value) {
    // console.log('Dirty to true')
    // Data stored in DB as null (e.g. a textbox left as empty during task creation) will fire updateValue when the modal is opened
    if (value !== null) this.dirty = true
    if (key === 'uiFormattedDuration') this.value = { ...this.value, duration: this.parseTimeStringToMinutes(String(value)) }
    if (key === 'uiFormattedCurrency') this.value = { ...this.value, ...this.parseCurrencyStringToObject(String(value)) }
    else this.value = { ...this.value, [key]: value }
  }

  parseCurrencyStringToObject (currencyString: string): { currency: string, cost: string } | void {
    //! Takes strings like: "CA$3", "£44.04", "£44.04"
    currencyString = currencyString.toLowerCase()

    let symbol = ''
    let beginning = true

    for (let index = 0; index < currencies.length; index++) {
      const item = currencies[index].symbol
      if (currencyString.startsWith(item)) {
        symbol = item
        beginning = true
        break
      } else if (currencyString.endsWith(item)) {
        symbol = item
        beginning = false
        break
      }
    }

    // If no found symbol, return empty
    if (!symbol) return

    let currency = ''
    let cost = 0

    if (beginning) {
      currency = currencyString.substr(currencyString.indexOf(symbol), symbol.length).trim()
      cost = parseFloat(currencyString.slice(currencyString.indexOf(symbol) + symbol.length).trim())
    } else {
      currency = currencyString.slice(-symbol.length).trim()
      cost = parseFloat(currencyString.slice(-(currencyString.indexOf(symbol) + symbol.length)).trim())
    }

    if (!isNaN(cost)) return { currency, cost: String(cost) }
  }

  parseTimeStringToMinutes (timeString: string): number {
    const weeks = timeString.match(/(\d+)\s*w/)
    const days = timeString.match(/(\d+)\s*d/)
    const hours = timeString.match(/(\d+)\s*h/)
    const minutes = timeString.match(/(\d+)\s*m/)

    let minuteCount = 0
    if (weeks) { minuteCount += parseInt(weeks[1]) * 60 * 24 * 7 }
    if (days) { minuteCount += parseInt(days[1]) * 60 * 24 }
    if (hours) { minuteCount += parseInt(hours[1]) * 60 }
    if (minutes) { minuteCount += parseInt(minutes[1]) }

    return minuteCount
  }

  minuteCountToTimeString (minutes: number): string {
    const weeks = Math.floor(minutes / (60 * 24 * 7))
    minutes -= weeks * (60 * 24 * 7)

    const days = Math.floor(minutes / (60 * 24))
    minutes -= days * (60 * 24)

    const hours = Math.floor(minutes / 60)
    minutes -= hours * 60

    let timeString = ''
    timeString += weeks ? weeks + 'w ' : ''
    timeString += days ? days + 'd ' : ''
    timeString += hours ? hours + 'h ' : ''
    timeString += minutes ? minutes + 'm ' : ''

    return timeString.trim()
  }

  deleteAssignee (index:number) : void {
    this.dirty = true
    this.assignees.splice(index, 1)
  }

  deleteFile (index:number) : void {
    this.dirty = true
    this.files.splice(index, 1)
  }

  addUser (user: Record<string, any>) : void {
    this.userListVisible = false
    const assigneeIds = this.assignees.map(assignee => assignee.id)
    // console.log('Adding assignee:', user)
    if (!assigneeIds.includes(user.id)) {
      this.dirty = true
      this.assignees.push(user)
      // this.$notify({ text: 'Assignee added', type: AlertType.SUCCESS })
    } else this.$notify({ text: 'Assignee is already added', type: AlertType.WARNING })
  }

  toggleUserList () : void {
    this.userListVisible = !this.userListVisible
  }

  toggleFileModal () : void {
    this.fileModalVisible = !this.fileModalVisible
  }

  attachFile (file) : void {
    this.files.push(file)
  }

  async fileAdded (fileId) : Promise<void> {
    const res = await api.files.getOneBaseFileControllerFile(fileId)
    const file: FileType = res.data
    this.files.push(file)
    this.fileModalVisible = false
    this.dirty = true
  }
}
