











import { Component, Vue } from 'vue-property-decorator'
import TopBar from '@/components/TheTopBar.vue'
import LogSchedulePageTopbar from './LogSchedulePageTopbar.vue'
import TicketBoard from './TicketBoard/index.vue'
import { MaintenanceLogType } from '@/api/SwaggerClient'
import { createNamespacedHelpers } from 'vuex'
const { mapActions } = createNamespacedHelpers('maintenanceLogs')

@Component({
  name: 'LogSchedulePage',
  components: {
    LogSchedulePageTopbar,
    TicketBoard,
    TopBar
  },
  methods: {
    ...mapActions({
      loadSelectedWeekLogs: 'loadSelectedWeekLogs'
    })
  }
})
export default class LogSchedulePage extends Vue {
  loadSelectedWeekLogs!: (weekRange?: {
    startOfWeekDate: string;
    endOfWeekDate: string;
  }) => Promise<void>

  private filterOptions: Record<string, any> = {
    status: {
      'to do': false,
      ongoing: false,
      cancelled: false,
      done: false
    },
    sort: 'AZ'
  }

  async created () {
    await this.loadSelectedWeekLogs()
  }
}
