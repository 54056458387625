


























import api from '@/services/api'
import { Component, Vue } from 'vue-property-decorator'
import FormField from '@/components/FormField.vue'
import store from '@/store/index'
import auth from '@/services/auth'
import storage from '@/services/storage'
import { AlertType } from '@/services/alerts/alert-options.type'
import { randomGreeting } from '@/services/ui'
const { VUE_APP_MODE } = process.env

  @Component({
    name: 'Register',
    components: {
      FormField
    }
  })
export default class Login extends Vue {
    private userFields = {
      email: '',
      password: '',
      firstName: '',
      lastName: ''
    }

    private verifyPassword = ''
    private isWeb = VUE_APP_MODE === 'web'
    private validInputs = {}
    private showValidation = false

    updateValidation (args) : void {
      this.validInputs[args.name] = !args.hasErrors
    }

    validationCheck () : boolean {
      var result = true
      for (const i in this.validInputs) {
        if (this.validInputs[i] === false) {
          result = false
          break
        }
      }
      return result
    }

    updateValue (key:string, value:string) : void {
      this.userFields = { ...this.userFields, [key]: value }
    }

    get IS_WEB () {
      return VUE_APP_MODE !== 'native'
    }

    async register () : void {
      if (!this.validationCheck()) {
        this.showValidation = true
        return
      }

      try {
        const response = await (await api.api.auth.authControllerRegister({
          email: this.userFields.email,
          password: this.userFields.password,
          firstName: this.userFields.firstName,
          lastName: this.userFields.lastName
        })).json()
        try {
          // Needed for nativescript (to save cookies in native "local storage")
          if (!this.IS_WEB) {
            const cookies = auth.parseCookies(response.headers['set-cookie'][0])
            storage.set('auth', JSON.stringify(cookies))
          }

          //* Success!
          store.dispatch('auth/setUser', response.data.user)
          store.commit('auth/setExpiry', response.data.jwtExpiry)
          this.$router.push('/equipment-list', { clearHistory: true })

          this.$notify({ text: randomGreeting() + ', ' + store.getters['auth/user'].firstName, type: AlertType.SUCCESS })
        } catch (error) {
          console.log('Device Error - ', error)
          this.$notify({ text: 'Sorry, there was a device error', type: AlertType.ERROR })
        }
      } catch (err) {
        if (err instanceof Response) {
          const body = await err.json() as Record<string, any>

          let errorMessage = 'Sorry, something went wrong, please try again later'
          if (body?.statusCode === 401) errorMessage = 'Incorrect email or password'
          if (body?.data?.message) errorMessage = body.data.message

          this.$notify({ text: errorMessage, type: AlertType.ERROR })
        } else this.$notify({ text: 'Unknown Error', type: AlertType.ERROR })
      }
    }
}
