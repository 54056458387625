










































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import TextFieldWithIcon from '@/components/TextFieldWithIcon.vue'
import AssigneeResult from '@/components/SearchBar/AssigneeResult.vue'
import { api } from '@/services/api'
import { createNamespacedHelpers } from 'vuex'
import { AuthUserType } from '@/api/SwaggerClient'
const { mapGetters, mapMutations } = createNamespacedHelpers('ui')

  @Component({
    name: 'AssigneeSearchBar',
    components: {
      TextFieldWithIcon,
      AssigneeResult
    },
    computed: {
      ...mapGetters({
        selectedEquipmentModal: 'selectedEquipmentModal',
        recentlySearchedAssignees: 'recentlySearchedAssignees'
      })
    },
    methods: {
      ...mapMutations({
        setEquipmentModalId: 'setEquipmentModalId',
        setEquipmentModalDisplay: 'setEquipmentModalDisplay',
        setMaintenanceModalId: 'setMaintenanceModalId',
        setMaintenanceModalDisplay: 'setMaintenanceModalDisplay',
        setRecentlySearchedAssignees: 'setRecentlySearchedAssignees'
      })
    }
  })
export default class AssigneeSearchBar extends Vue {
  @Prop(String) private hint!:string;

  // VueX variables
  setEquipmentModalId!: (val: number) => void
  setEquipmentModalDisplay!: (val: boolean) => void
  setMaintenanceModalId!: (val: number) => void
  setMaintenanceModalDisplay!: (val: boolean) => void
  setRecentlySearchedAssignees!:(val: AuthUserType[]) => void
  recentlySearchedAssignees!: AuthUserType[]

  searchTerm = '';
  showSearchResults = false;
  searchClearModeEnabled = false;
  searchTask: ReturnType<typeof setTimeout> = setTimeout(() => '', 1);
  isSearching = false;
  maxResultItemsShown = 5;

  results: Array<Record<string, any>> = []
  // recentlySearchedAssignees: Array<Record<string, any>> = this.recentlySearchedAssignees;

  openResult (result: Record<string, any>, index: number): void {
    // Hide search bar
    this.showSearchResults = false
    // If duped - remove duped item from array before adding it back (on top)
    // eslint-disable-next-line eqeqeq
    const dupeIndex = this.recentlySearchedAssignees.findIndex(element => element.type == result.type && element.id == result.id)
    if (dupeIndex !== -1) {
      this.recentlySearchedAssignees.splice(dupeIndex, 1)
    }
    // Add recent result
    this.recentlySearchedAssignees.push(result as AuthUserType)

    this.$emit('add', result.user)
  }

  clearRecentResults () {
    this.setRecentlySearchedAssignees([])
  }

  getFASCode (type: string): string {
    switch (type) {
      case 'equipment':
        return '&#xf013;'
      case 'log':
        return '&#xf022;'
      default:
        return '&#xf128;'
    }
  }

  // Use this when type input (waits a full second before making search request)
  preSearch (): void {
    this.isSearching = true
    clearTimeout(this.searchTask)
    this.searchTask = setTimeout(() => {
      this.search()
    }, 500)
  }

  // Use "search" to actually make the HTTP search requests
  async search (): Promise<void> {
    const userSearch = await (await api.users.usersControllerSearch({ term: this.searchTerm })).json()
    this.results = userSearch.map(user => {
      return {
        id: user.id,
        header: user.firstName + ' ' + user.lastName,
        tag: '',
        body: 'User',
        user
      }
    })

    this.isSearching = false
  }

  @Watch('searchTerm')
  onSearchTermChanged (val: string, oldVal: string): void {
    this.showSearchResults = !!val
    this.searchClearModeEnabled = !!val
    this.preSearch()
  }

  textFieldFocus (): void {
    console.log('Assignee searchbar focus')
    this.showSearchResults = true
  }

  // Commented because clicking on search result would close the "popup"
  // textFieldBlur (): void {
  //   this.showSearchResults = false
  // }

  searchTermUpdated (newTerm: string): void {
    this.searchTerm = newTerm
  }
}
