


































import { Component, Vue, Prop } from 'vue-property-decorator'
import TextFieldWithIcon from '@/components/TextFieldWithIcon.vue'
import SearchResult from '@/components/SearchResult.vue'
import SelectModal from './SelectModal.vue'

  @Component({
    name: 'Select',
    components: {
      TextFieldWithIcon,
      SearchResult
    }
  })
export default class Select extends Vue {
  @Prop(Array) list
  @Prop(String) title
  @Prop(String) helper
  @Prop(String) openPrefixIcon
  @Prop(String) openSuffixIcon
  @Prop(String) closedPrefixIcon
  @Prop(String) closedSuffixIcon
  @Prop(Boolean) showSelected

  searchTerm = '';
  showSelect = false;
  selectedIndex = 0

  selected (index: number): void {
    this.selectedIndex = index
    this.showSelect = false
    this.$emit('selected', index)
  }

  showSelectModal () {
    this.$showModal(SelectModal, { target: this, fullscreen: false, props: { list: this.list.map(li => li.name) } }).then((args: number) => {
      this.selected(args)
    })
  }
}
