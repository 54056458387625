import formatDateToHTML from './formatDateToHTML'
export default function calculateWeekRange (date: string): {
  startOfWeekDate: string
  endOfWeekDate: string
} {
  const selectedDateString: string = String(date).replace(/-/g, '/')
  let selectedDate = new Date(selectedDateString)
  let selectedDayOfWeek = selectedDate.getDay()
  const isSunday = selectedDayOfWeek === 0
  if (isSunday) {
    const previousDayinMillis = selectedDate.setDate(selectedDate.getDate() - 1)
    selectedDate = new Date(previousDayinMillis)
    selectedDayOfWeek = selectedDate.getDay()
  }

  const startOfWeekDiff = selectedDate.getDate() - selectedDayOfWeek + 1
  const startOfWeekDate = formatDateToHTML(
    new Date(selectedDate.setDate(startOfWeekDiff))
  ) // always a monday

  const endOfWeekDiff = new Date(startOfWeekDate).getDate() + 6
  const endOfWeekDate = formatDateToHTML(
    new Date(selectedDate.setDate(endOfWeekDiff))
  ) // always a sunday

  return {
    startOfWeekDate,
    endOfWeekDate
  }
}
