import Home from '../../views/Home.vue'
import LoginPage from '../../views/LoginPage.vue'
import LogoutPage from '@/views/LogoutPage.vue'
import RegisterPage from '../../views/RegisterPage.vue'
import ForgotPasswordPage from '../../views/ForgotPasswordPage.vue'
import Dev from '@/views/Dev.vue'
import EquipmentLogForm from '@/components/EquipmentLogForm.vue'
import EquipmentListPage from '@/views/EquipmentListPage/index.vue'
import LogSchedulePage from '@/views/LogSchedulePage/index.vue'

export const routes = [
  // {
  //   path: '/home',
  //   name: 'Home',
  //   component: Home,
  //   meta: {
  //     topBarOptions: {
  //       navigationButton: true,
  //       searchBar: true,
  //       profile: true
  //     },
  //     requiresAuth: false
  //   }
  // },
  {
    // This is an alias for "login" - It just resolves the TopBar issue
    path: '/logout',
    name: 'Logout',
    component: LogoutPage,
    meta: {
      topBarOptions: {
        navigationButton: false,
        searchBar: false,
        profile: false
      },
      requiresAuth: false,
      loggedInRedirection: '/equipment-list'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
    meta: {
      topBarOptions: {
        navigationButton: false,
        searchBar: false,
        profile: false
      },
      requiresAuth: false,
      loggedInRedirection: '/equipment-list'
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: RegisterPage,
    meta: {
      topBarOptions: {
        navigationButton: false,
        searchBar: false,
        profile: false
      },
      requiresAuth: false,
      loggedInRedirection: '/equipment-list'
    }
  },
  {
    path: '/recover-password',
    name: 'Recover Password',
    component: ForgotPasswordPage,
    meta: {
      topBarOptions: {
        navigationButton: false,
        searchBar: false,
        profile: false
      },
      requiresAuth: false,
      loggedInRedirection: '/equipment-list'
    }
  },
  {
    path: '/equipment-log',
    name: 'Equipment Log Form',
    component: EquipmentLogForm,
    meta: {
      topBarOptions: {
        navigationButton: true,
        searchBar: true,
        profile: true
      },
      requiresAuth: false,
      loggedInRedirection: '/home'
    }
  },
  {
    path: '/dev',
    name: 'Development Area',
    component: Dev,
    meta: {
      topBarOptions: {
        navigationButton: true,
        searchBar: true,
        profile: true
      },
      requiresAuth: true
    }
  },
  {
    path: '/log-schedule',
    name: 'Log Schedule',
    component: LogSchedulePage,
    meta: {
      icon: '&#xf073;',
      topBarOptions: {
        navigationButton: true,
        searchBar: true,
        profile: true
      },
      requiresAuth: true
    }
  },
  {
    path: '/equipment-list',
    name: 'Equipment Page',
    component: EquipmentListPage,
    meta: {
      icon: '&#xf03a;',
      topBarOptions: {
        navigationButton: true,
        searchBar: true,
        profile: true
      },
      requiresAuth: true
    }
  }
]

// // Optional
// meta: {
//   isVertical: true,
//   // Example actions to dispatch automatically when page is visited
//   // Remember that you need to implement actions in your Vuex store
//   store: {
//     // Call action to hide navigation buttons
//     showNavigationButtons: false,

//     // Call "showMovies" action in "categories" module with payload "all"
//     'categories/showMovies': 'all'

//     // Call action without payload
//     // showNavigationButtons: null
//   }
// }
