































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import TextFieldWithIcon from '@/components/TextFieldWithIcon.vue'
import SearchResult from '@/components/SearchResult.vue'
import SearchBarBase from '@/components/SearchBar/SearchBarBase.vue'
import { api } from '@/services/api'
import { createNamespacedHelpers } from 'vuex'
const { mapGetters, mapMutations } = createNamespacedHelpers('ui')

  @Component({
    name: 'TopOmniSearchBar',
    components: {
      TextFieldWithIcon,
      SearchResult,
      SearchBarBase
    },
    computed: {
      ...mapGetters({
        selectedEquipmentModal: 'selectedEquipmentModal'
      })
    },
    methods: {
      ...mapMutations({
        setEquipmentModalId: 'setEquipmentModalId',
        setEquipmentModalDisplay: 'setEquipmentModalDisplay',
        setMaintenanceModalId: 'setMaintenanceModalId',
        setMaintenanceModalDisplay: 'setMaintenanceModalDisplay'
      })
    }
  })
export default class TopOmniSearchBar extends Vue {
  @Prop(String) private hint!:string;

  // VueX variables
  setEquipmentModalId!: (val: number) => void
  setEquipmentModalDisplay!: (val: boolean) => void
  setMaintenanceModalId!: (val: number) => void
  setMaintenanceModalDisplay!: (val: boolean) => void

  searchTerm = '';
  showSearchResults = false;
  searchClearModeEnabled = false;
  searchTask: ReturnType<typeof setTimeout> = setTimeout(() => '', 1);
  isSearching = false;
  maxResultItemsShown = 5;

  results: Array<Record<string, any>> = []
  recentResults: Array<Record<string, any>> = [];

  openResult (result: Record<string, any>, index: number): void {
    // Hide search bar
    this.showSearchResults = false
    // If duped - remove duped item from array before adding it back (on top)
    // eslint-disable-next-line eqeqeq
    const dupeIndex = this.recentResults.findIndex(element => element.type == result.type && element.id == result.id)
    if (dupeIndex !== -1) {
      this.recentResults.splice(dupeIndex, 1)
    }
    // Add recent result
    this.recentResults.push(result)

    // Show modal - abd load entity with id
    if (result.type === 'log') {
      this.setMaintenanceModalId(result.id)
      this.setMaintenanceModalDisplay(true)
    } else if (result.type === 'equipment') {
      this.setEquipmentModalId(result.id)
      this.setEquipmentModalDisplay(true)
    }
  }

  clearRecentResults () {
    this.recentResults = []
  }

  getFASCode (type: string): string {
    switch (type) {
      case 'equipment':
        return '&#xf013;'
      case 'log':
        return '&#xf022;'
      default:
        return '&#xf128;'
    }
  }

  // Use this when type input (waits a full second before making search request)
  preSearch (): void {
    this.isSearching = true
    clearTimeout(this.searchTask)
    this.searchTask = setTimeout(() => {
      this.search(this.searchTerm)
    }, 500)
  }

  // Use "search" to actually make the HTTP search requests
  async search (term: string): Promise<void> {
    // Run HTTP requests in parallel
    const equipmentSearch = api.equipment.equipmentControllerSearch({ term })
    const maintenanceLogSearch = api.maintenanceLog.maintenanceLogControllerSearch({ term })

    // Wait
    const finishedEquipmentSearch = await equipmentSearch
    const finishedMaintenanceLogSearch = await maintenanceLogSearch

    // Turn TS interface-d types into "search result" objects (with header, tag, body, type)
    const equipmentResults = finishedEquipmentSearch.data.map(equipment => { return { id: equipment.id, header: equipment.name, tag: equipment.serialNumber, body: '', type: 'equipment' } })
    const maintenanceLogResults = finishedMaintenanceLogSearch.data.map(log => { return { id: log.id, header: log.title, tag: log.status + '', body: log.description, type: 'log' } })

    // Old way - had issues using the mixed type array to convert into "searchResult" compatible type (Record<string, any> for now)
    // const finishedSearches = await Promise.allSettled([equipmentSearch, maintenanceLogSearch])
    const combinedResults = maintenanceLogResults.concat(equipmentResults)

    this.results = combinedResults
    this.isSearching = false
  }

  @Watch('searchTerm')
  onSearchTermChanged (val: string, oldVal: string): void {
    this.showSearchResults = !!val
    this.searchClearModeEnabled = !!val
    this.preSearch()
  }

  textFieldFocus (): void {
    this.showSearchResults = true
  }

  // Commented because clicking on search result would close the "popup"
  // textFieldBlur (): void {
  //   this.showSearchResults = false
  // }

  searchTermUpdated (newTerm: string): void {
    this.searchTerm = newTerm
  }
}
