import { vm } from '@/main'
import { api } from './api'

const logout = async function (reason: string): Promise<void> {
  //! This function CAN run before vm is set (usually on browser refresh - just bare that in mind)
  console.log('Logout Event: Reason: ' + reason)
  try {
    await api.auth.authControllerLogout({}).catch(error => {
      console.log('Error : Logout HTTP Call')
      console.log(error)
    })

    // E.g. If client was logged out on home page (take to login)
    if (vm.$router.currentRoute.meta?.requiresAuth) {
      console.log('Going to LOGOUT PAGE')
      try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        vm.$goto('/logout', { clearHistory: true })
      } catch (error) {
        console.log('Error : Going to LOGIN PAGE')
        console.log(error)
      }
    }
  } catch (error) {
    console.log('Error : Logout Event')
    console.log(error)
  }
}

export default { logout }
export { logout }
