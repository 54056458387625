


















import { Component, Vue } from 'vue-property-decorator'
import Sidebar from '@/views/EquipmentListPage/Sidebar/index.vue'
import EquipmentDetails from '@/views/EquipmentListPage/EquipmentDetails/index.vue'
import { createNamespacedHelpers } from 'vuex'
import { EquipmentType } from '@/api/SwaggerClient'
import ImageCarouselModal from '@/components/ImageCarouselModal.vue'
const { mapActions, mapGetters } = createNamespacedHelpers('equipment')
const { VUE_APP_MODE } = process.env

@Component({
  name: 'EquipmentListPage',
  components: {
    Sidebar,
    EquipmentDetails
  },
  methods: {
    ...mapActions({
      loadAllEquipment: 'loadAllEquipment'
    })
  },
  computed: {
    ...mapGetters({
      selectedEquipment: 'selectedEquipment'
    })
  }
})
export default class EquipmentListPage extends Vue {
  loadAllEquipment!: () => Promise<void>

  navigationSidePanelOpen = false
  isReady = false

  selectedEquipment!: EquipmentType | null

  async created () {
    try {
      await this.loadAllEquipment()
    } catch (e) {
      console.log(e)
    }
    this.isReady = true
  }

  get orientation () {
    return VUE_APP_MODE === 'native' ? 'vertical' : 'horizontal'
  }

  get IS_WEB () {
    return VUE_APP_MODE !== 'native'
  }
}
