import Vue from 'vue'
import { TYPE } from 'vue-toastification'
import { AlertOptions, AlertType } from './alert-options.type'

function notify (options: AlertOptions): void {
  const { text, type } = options

  let convertedType: TYPE

  switch (type) {
    case AlertType.ERROR:
      convertedType = TYPE.ERROR
      break
    case AlertType.INFO:
      convertedType = TYPE.INFO

      break
    case AlertType.SUCCESS:
      convertedType = TYPE.SUCCESS

      break
    case AlertType.WARNING:
      convertedType = TYPE.WARNING

      break
    default:
      convertedType = TYPE.DEFAULT
      break
  }

  Vue.$toast(text, { type: convertedType })
}

export { notify }
