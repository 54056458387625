import { MaintenanceLogSortOptions, PaginatedNestedEquipmentLogsRequest } from './../../global/types'
import { PaginatedResponseDtoType, CreateMaintenanceLogDtoType, EquipmentType, MaintenanceLogType, HttpResponse } from '@/api/SwaggerClient'
import { api } from '@/services/api'
import calculateWeekRange from '@/utils/calculateWeekRange'
import formatDateToHTML from '@/utils/formatDateToHTML'

import {
  CondOperator,
  RequestQueryBuilder,
  RequestQueryBuilderOptions
} from '@nestjsx/crud-request'
export const maintenanceLogs = {
  namespaced: true,
  state: {
    allLogs: [],
    selectedWeekLogs: [],
    logsTableLimit: 25,
    logsTablePage: 1,
    logsTableSortBy: MaintenanceLogSortOptions.NEWEST as MaintenanceLogSortOptions
  },

  mutations: {
    SET_ALL_LOGS (state, allLogs: MaintenanceLogType[]): void {
      state.allLogs = allLogs
    },
    SET_SELECTED_WEEK_LOGS (state, selectedWeekLogs) {
      state.selectedWeekLogs = selectedWeekLogs
    },
    SET_LOGS_TABLE_LIMIT (state, limit: number) {
      state.logsTableLimit = limit
    },
    SET_LOGS_TABLE_PAGE (state, page: number) {
      state.logsTablePage = page
    },
    SET_LOGS_TABLE_SORT_BY (state, sortBy: MaintenanceLogSortOptions) {
      state.logsTableSortBy = sortBy
    }
  },
  actions: {
    setLogsTableLimit ({ commit }, limit: number) {
      commit('SET_LOGS_TABLE_LIMIT', limit)
    },
    setLogsTablePage ({ commit }, page: number | undefined) {
      if (!page) page = 1
      commit('SET_LOGS_TABLE_PAGE', page)
    },
    setLogsTableSortBy ({ commit }, sortBy: MaintenanceLogSortOptions | undefined) {
      if (!sortBy) sortBy = MaintenanceLogSortOptions.NEWEST
      commit('SET_LOGS_TABLE_SORT_BY', sortBy)
    },
    async createNewLog ({ commit }, createMaintenanceLogDto: CreateMaintenanceLogDtoType): Promise<HttpResponse<MaintenanceLogType, any>> {
      const res = api.maintenanceLog.createOneBaseMaintenanceLogControllerMaintenanceLog(createMaintenanceLogDto)
      return res
    },
    async loadLogsById ({ commit }, id: number): Promise<MaintenanceLogType> {
      const res = await api.maintenanceLog.getOneBaseMaintenanceLogControllerMaintenanceLog(id)
      console.log(res.data)
      return res.data
    },
    async loadLogsByEquipment ({ commit }, id: number) {
      // const res = await api.equipment.getOneBaseEquipmentControllerEquipment(id)
      // console.log(res.data)
      // return res.data
      const query = RequestQueryBuilder.create({
        filter: [
          {
            field: 'equipment.id',
            operator: CondOperator.EQUALS,
            value: id
          }
        ]
      }).queryObject

      const res = await api.maintenanceLog.getManyBaseMaintenanceLogControllerMaintenanceLog(query)
      const equipmentLogs = Array.isArray(res.data) ? res.data : res.data.data

      return equipmentLogs
      // commit('SET_EQUIPMENT_LOGS', res.data)
    },
    async loadAllLogs ({ commit }): Promise<void> {
      const res =
        await api.maintenanceLog.getManyBaseMaintenanceLogControllerMaintenanceLog()
      const allLogs = Array.isArray(res.data) ? res.data : res.data.data
      commit('SET_ALL_LOGS', allLogs)
    },
    async loadSelectedWeekLogs ({ commit, rootGetters }, weekRange?: {
      startOfWeekDate: string;
      endOfWeekDate: string;
    }): Promise<void> {
      if (!weekRange) {
        weekRange = calculateWeekRange(rootGetters['calendar/selectedDate'])
      }
      const { startOfWeekDate, endOfWeekDate } = weekRange

      const query = RequestQueryBuilder.create({
        filter: [
          {
            field: 'executionDate',
            operator: CondOperator.GREATER_THAN_EQUALS,
            value: startOfWeekDate
          },
          {
            field: 'executionDate',
            operator: CondOperator.LOWER_THAN_EQUALS,
            value: endOfWeekDate
          }
        ]
      }).queryObject

      const res =
        await api.maintenanceLog.getManyBaseMaintenanceLogControllerMaintenanceLog(query)
      const selectedWeekLogs = Array.isArray(res.data)
        ? res.data
        : res.data.data

      commit('SET_SELECTED_WEEK_LOGS', selectedWeekLogs)
    },
    async updateMaintenanceLog ({ commit, dispatch }, { logId, updateMaintenanceLog }): Promise<HttpResponse<MaintenanceLogType, any>> {
      const res = await api.maintenanceLog.updateOneBaseMaintenanceLogControllerMaintenanceLog(logId, updateMaintenanceLog)
      return res
    },
    async loadEquipmentNestedLogs ({ commit }, { limit, page, equipmentId, sortBy }): Promise<PaginatedResponseDtoType & {
      results?: MaintenanceLogType[] | undefined;
  }> {
      const res = await api.maintenanceLog.maintenanceLogControllerGetEquipmentNestedLogs(equipmentId, { page, limit, sortBy })

      return res.data
    }
  },
  async updateMaintenanceLog ({ commit, dispatch }, { logId, updateMaintenanceLog }): Promise<HttpResponse<MaintenanceLogType, any>> {
    const res = await api.maintenanceLog.updateOneBaseMaintenanceLogControllerMaintenanceLog(logId, updateMaintenanceLog)
    return res
  },
  getters: {
    allLogs (state): void {
      return state.allLogs
    },
    selectedWeekLogs (state, getters, rootState, rootGetters): any[] {
      return daysOfTheWeek.map(({ key, dayName }) => {
        const selectedDate = new Date(rootGetters['calendar/selectedDate'])
        let modifier = key
        if (dayName === 'Sunday') {
          modifier = 7
        }
        const dateForDay = formatDateToHTML(
          new Date(
            selectedDate.setDate(
              selectedDate.getDate() - selectedDate.getDay() + modifier
            )
          )
        )
        return {
          day: dayName,
          date: dateForDay,
          logs: state.selectedWeekLogs.filter(
            (log) =>
              new Date(formatDateToHTML(log.executionDate)).getDay() === key
          )
        }
      })
    }
  }
}

const daysOfTheWeek: { key: number; dayName: string }[] = [
  { key: 1, dayName: 'Monday' },
  { key: 2, dayName: 'Tuesday' },
  { key: 3, dayName: 'Wednesday' },
  { key: 4, dayName: 'Thursday' },
  { key: 5, dayName: 'Friday' },
  { key: 6, dayName: 'Saturday' },
  { key: 0, dayName: 'Sunday' }
]
