
<template web>
  <ul
    v-if="context.attributes.showValidation"
    :class="context.classes.errors"
  >
    <li
      v-for="error in context.validationErrors"
      :key="error"
      :class="context.classes.error"
      :role="'status'"
      :aria-live="'polite'"
      v-text="error"
    />
  </ul>
</template>

<script>
export default {
  props: {
    context: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
</style>
