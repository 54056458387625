










































import { Component, Vue, Prop } from 'vue-property-decorator'
import { MaintenanceLogType, AuthUserType } from '@/api/SwaggerClient'
import EquipmentLogForm from '@/components/EquipmentLogForm.vue'
import { createNamespacedHelpers } from 'vuex'
const { mapMutations: mapMutationsUi } = createNamespacedHelpers('ui')

@Component({
  name: 'MaintenanceLogCard',
  methods: {
    ...mapMutationsUi({
      setMaintenanceModalDisplay: 'setMaintenanceModalDisplay',
      setMaintenanceModalId: 'setMaintenanceModalId'
    })
  }
})
export default class MaintenanceLogCard extends Vue {
  @Prop(Object)
  log!: MaintenanceLogType

  setMaintenanceModalDisplay!: (val: boolean) => void
  setMaintenanceModalId!: (val: number) => void

  assigneeInitials (assignee: AuthUserType) {
    const initials =
      assignee.firstName.charAt(0).toUpperCase() +
      assignee.lastName.charAt(0).toUpperCase()
    return initials
  }

  openEditLogModal () {
    this.setMaintenanceModalId(this.log.id)
    this.setMaintenanceModalDisplay(true)

    if (!this.$isWeb) {
      this.$showModal(EquipmentLogForm, {
        fullscreen: true,
        props: { mode: 'edit' }
      })
    }
  }

  get bodyColor () {
    switch (this.log.status) {
      case 'To Do':
        return 'bg-gray-200'
      case 'Ongoing':
        return 'bg-blue-100'
      case 'Done':
        return 'bg-green-100'
      case 'Cancelled':
        return 'bg-red-100'
    }
  }

  get sideRibbonColor () {
    switch (this.log.status) {
      case 'To Do':
        return 'bg-gray-800'
      case 'Ongoing':
        return 'bg-blue-700'
      case 'Done':
        return 'bg-green-700'
      case 'Cancelled':
        return 'bg-red-700'
    }
  }

  get textStatusColor () {
    switch (this.log.status) {
      case 'To Do':
        return 'text-gray-700'
      case 'Ongoing':
        return 'text-blue-700'
      case 'Done':
        return 'text-green-700'
      case 'Cancelled':
        return 'text-red-700'
    }
  }
}
