




















import { MaintenanceLogType } from '@/api/SwaggerClient'
import { Component, Prop, Vue } from 'vue-property-decorator'
import MaintenanceLogCard from './MaintenanceLogCard.vue'

@Component({
  name: 'TicketBoardColumn',
  components: {
    MaintenanceLogCard
  }
})
export default class TicketBoardColumn extends Vue {
  @Prop({ type: Array, required: true, default: [] })
  logs!: MaintenanceLogType[]

  @Prop({ type: String, required: true })
  dayOfWeek!: string

  @Prop({ type: String, required: true })
  date!: string

  get formattedDate () {
    const dateArray = this.date.split('-')
    return `${dateArray[2]}/${dateArray[1]}`
  }
}
