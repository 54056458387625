

















import { Component, Vue } from 'vue-property-decorator'
import Register from '@/components/Register.vue'

  @Component({
    name: 'RegisterPage',
    components: {
      Register
    }
  })
export default class RegisterPage extends Vue {
}

