
































import Vue from 'vue'
import FormField from '@/components/FormField.vue'
import currencies from '@/assets/currencies.json'

export default Vue.extend({
  components: {
    FormField
  },
  props: {
    startValue: Object
  },
  data: () => {
    return {
      currencies: currencies,
      cost: {
        currency: { name: 'Polish Zloty', symbolNative: 'zł', positionAfter: true, symbol: 'zł' },
        amount: null
      },
      loaded: false
    }
  },
  methods: {
    updateAmount (amount) {
      this.cost.amount = amount
      this.$emit('input', this.cost)
    },
    updateCurrency (currency) {
      this.cost.currency = currency
      this.$emit('input', this.cost)
    }
  },
  mounted () {
    if (this.startValue) {
      if (this.startValue.currency.symbol) this.cost.currency = this.startValue.currency
      if (this.startValue.amount) this.cost.amount = this.startValue.amount
    }

    Vue.nextTick(() => {
      console.log('Emitting - ', this.cost)
      // this.$emit('input', this.cost)
    })
    this.loaded = true
  }
})

