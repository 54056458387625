import { topBar } from '@/store/modules/top-bar'
import { calendar } from './modules/calendar'
import Vue from 'vue'
import Vuex from 'vuex'
import { auth } from 'pb-frontend-lib'
import { maintenanceLogs } from '@/store/modules/maintenance-logs'
import { equipment } from '@/store/modules/equipment'
import { ui } from '@/store/modules/ui'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isNative: process.env.VUE_APP_MODE === 'native'
  },
  mutations: {},
  actions: {},
  getters: {
    isNative (state) {
      return state.isNative
    }
  },
  modules: {
    auth,
    maintenanceLogs,
    calendar,
    equipment,
    topBar,
    ui
  }
})
