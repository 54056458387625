















import { Component, Prop, Vue } from 'vue-property-decorator'
import TicketBoardColumn from './TicketBoardColumn/index.vue'
import { MaintenanceLogType } from '@/api/SwaggerClient'
import { createNamespacedHelpers } from 'vuex'
const { mapGetters } = createNamespacedHelpers('maintenanceLogs')
@Component({
  name: 'TicketBoard',
  components: {
    TicketBoardColumn
  },
  computed: {
    ...mapGetters({
      selectedWeekLogs: 'selectedWeekLogs'
    })
  }
})
export default class TicketBoard extends Vue {
  @Prop() filterOptions! : Record<string, any>
  selectedWeekLogs!: {
    day: string
    date: string
    logs: MaintenanceLogType[]
  }[]

  filter (logs) {
    let filteredLogs: MaintenanceLogType[] = logs

    const filterByUser = this.filterOptions?.showLogByAssignee === 'user'
    if (filterByUser) {
      filteredLogs = filteredLogs.filter((log) => {
        let isAssignedLog = false
        if (log.assignees?.length) {
          isAssignedLog = log.assignees.some((assignee) => {
            if (assignee.id === this.$store.getters['auth/user'].id) {
              return true
            }
          })
        }
        return isAssignedLog
      })
    }

    let skipStatusFilter = true
    for (const filter in this.filterOptions?.status) {
      if (this.filterOptions.status[filter]) skipStatusFilter = false
    }

    if (!skipStatusFilter) {
      filteredLogs = filteredLogs.filter((log) => {
        for (const filter in this.filterOptions.status) {
          if (log.status?.toLowerCase() === filter.toLowerCase()) {
            return this.filterOptions.status[filter]
          }
        }
      })
    }

    const sortLogs = this.filterOptions?.sort
    if (sortLogs) {
      filteredLogs.sort((a, b) => {
        const titleA = b.title.toLowerCase()
        const titleB = a.title.toLowerCase()
        if (titleA > titleB) return -1
        if (titleB < titleA) return 1
        return 0
      })
      if (sortLogs === 'ZA') {
        filteredLogs = [...filteredLogs.reverse()]
      }
    }
    return filteredLogs
  }
}
