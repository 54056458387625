

























































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import SearchField from '@/components/SearchField.vue'
import XDateInput from '@/components/XDateInput.vue'

  @Component({
    name: 'FormField',
    components: {
      XDateInput
    }
  })
export default class FormField extends Vue {
  @Prop(String) private title!:string;
  @Prop(String) private description!:string;
  @Prop({ default: '' }) private hint!:string;
  @Prop() private value!:unknown;
  @Prop({ default: () => { return {} } }) private props!:Record<string, any>;
  @Prop(String) private type!:string;
  @Prop(Array) private options!:Array<Record<string, unknown>>;
  @Prop(String) private webInputStyle!:string;
  @Prop(String) private placeholderText!:string;
  @Prop(String) private searchLabel!:string;
  @Prop(String) private keyboardType!:string;
  @Prop(String) private validation!:string;
  @Prop(Boolean) private fixedErrors!:boolean;
  @Prop(Boolean) private secure!:boolean;
  @Prop(Boolean) private showValidation!:boolean;
  @Prop(Boolean) private hideAstrisks!:boolean;
  @Prop() private startValue!:any;
  @Prop() private displayLabel!:string;
  @Prop() private validationMessages!:string;
  @Prop() private elementStyle!:string;
  @Prop() private formulateInputStyle!:string;

  private mutableValue:unknown = '';
  private searchToggled = false;
  private nsMatch = {
    textarea: 'nsTextArea',
    text: 'nsTextField',
    search: 'nsSearch',
    date: 'nsDatePicker'
  }

  created (): void {
    console.log('FormField Props - ', this.props)
    this.mutableValue = this.value
    if (this.startValue) {
      this.mutableValue = this.startValue
    }
  }

  validationEvent (args) {
    // console.log('Validation Event', args) // Causes a lot of console.log's
    this.$emit('validation', args)
  }

  @Watch('value')
  onValueChange () {
    // console.log('VALUE CHANGE', this.value)
    // this.mutableValue = this.value
  }

  showSearchModal () {
    this.$showModal(SearchField, { target: this, fullscreen: true, props: { options: this.options, placeholderText: this.placeholderText ? this.placeholderText : 'Search', label: this.displayLabel ? this.displayLabel : this.searchLabel ? this.searchLabel : 'name' } }).then((args) => {
      this.$emit('input', args)
    })
  }
}

