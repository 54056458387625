


import { Component, Vue, VModel, Prop } from 'vue-property-decorator'

@Component({
  name: 'DateModal'
})
export default class DateModal extends Vue {
  @VModel({ type: String }) innerValue!: string
  @Prop() initialDateValue !: Date
  private date = new Date()

  mounted () : void {
    if (this.initialDateValue) this.date = this.initialDateValue
  }

  close () : void {
    this.$modal.close(this.date)
  }
}
