import { api } from '@/services/api'
import auth from '@/services/auth'
import storage from '@/services/storage'
import _ from 'lodash'
import { CreateEquipmentDtoType, EquipmentType, HttpResponse } from '@/api/SwaggerClient'
import { CondOperator, RequestQueryBuilder } from '@nestjsx/crud-request'

const { VUE_APP_PLATFORM } = process.env

export const equipment = {
  namespaced: true,
  state: {
    allEquipment: [] as EquipmentType[], // flat structure (every equipment no matter parent or child will be returned as a root not nested)
    allEquipmentTree: [] as EquipmentType[], // tree structure (equipment are nested parent -> child -> child)
    selectedEquipment: null as EquipmentType | null,
    equipmentNavigationPath: [] as EquipmentType[],
    cachedEquipment: [] as EquipmentType[],
    sidebarEquipmentList: [] as EquipmentType[]
  },
  mutations: {
    SET_ALL_EQUIPMENT (state, allEquipment: EquipmentType[]): void {
      state.allEquipment = allEquipment
    },
    SET_ALL_EQUIPMENT_TREE (state, allEquipmentTree: EquipmentType[]): void {
      state.allEquipmentTree = allEquipmentTree
    },
    SET_SIDEBAR_EQUIPMENT_LIST (state, equipmentList: EquipmentType[]): void {
      state.sidebarEquipmentList = equipmentList
    },
    SET_SELECTED_EQUIPMENT (state, equipment: EquipmentType | null) {
      state.selectedEquipment = equipment
      if (equipment) {
        const newSelectedEquipment = state.cachedEquipment.filter(e => e.id === equipment.id).length === 0
        if (newSelectedEquipment) {
          state.cachedEquipment.push(equipment)
        }
      }
    },
    SET_EQUIPMENT_NAVIGATION_PATH (state, path) {
      state.equipmentNavigationPath = path
    }
  },
  actions: {
    async loadAllEquipment ({ commit, dispatch, state }): Promise<void> {
      // const sortByLatestQuery = RequestQueryBuilder.create({ // doesnt work yet because of manual parent / trees override method on backend
      //   sort: [
      //     {
      //       field: 'equipment.createdAt',
      //       order: 'ASC'
      //     }
      //   ]
      // }).queryObject
      const res = await api.equipment.getManyBaseEquipmentControllerEquipment()
      const allEquipment = Array.isArray(res.data) ? res.data : res.data.data

      commit('SET_ALL_EQUIPMENT', allEquipment.reverse())

      await dispatch('loadAllEquipmentAsTree')
      if (state.allEquipmentTree.length) { // refactor and improve later
        await dispatch('setSidebarEquipmentList', state.allEquipmentTree)
        if (VUE_APP_PLATFORM === 'web') {
          await dispatch('setSelectedEquipment', state.allEquipmentTree[0])
        }
      } else {
        await dispatch('setSidebarEquipmentList', [])
        await dispatch('setEquipmentNavigationPath', [])
        await dispatch('setSelectedEquipment', null)
      }
    },
    async loadAllEquipmentAsTree ({ commit }) {
      const res = await api.equipment.equipmentControllerGetAllEquipmentAsTree()
      const allEquipmentTree = res.data

      commit('SET_ALL_EQUIPMENT_TREE', allEquipmentTree.reverse())
    },
    async navigateToEquipmentInPathStore ({ commit, state, dispatch }, equipmentToNavigateTo: EquipmentType) {
      if (VUE_APP_PLATFORM === 'web') {
        equipmentToNavigateTo = await dispatch('getEquipmentFromCache', equipmentToNavigateTo.id)

        if (equipmentToNavigateTo.id === state.selectedEquipment.id) return // if tries to navigate to the current selected equipmentToNavigateTo
        const equipmentIsRootInNavigationPath = equipmentToNavigateTo.id === state.equipmentNavigationPath[0].id
        if (equipmentIsRootInNavigationPath) {
          dispatch('setSidebarEquipmentList', state.allEquipmentTree)
          dispatch('setSelectedEquipment', equipmentToNavigateTo)
          return
        }
        if (equipmentToNavigateTo.parentEquipment) {
          const parent = await dispatch('getEquipmentFromCache', equipmentToNavigateTo.parentEquipment?.id)
          dispatch('setSidebarEquipmentList', parent.childrenEquipment!)
        } else {
          dispatch('setSidebarEquipmentList', equipmentToNavigateTo.childrenEquipment!)
        }
        dispatch('setSelectedEquipment', equipmentToNavigateTo)
      } else {
        equipmentToNavigateTo = await dispatch('getEquipmentFromCache', equipmentToNavigateTo.id)

        if (equipmentToNavigateTo.id === state.selectedEquipment.id) return // if tries to navigate to the current selected equipmentToNavigateTo

        dispatch('setSelectedEquipment', equipmentToNavigateTo)
        dispatch('setSidebarEquipmentList', equipmentToNavigateTo.childrenEquipment!)
      }
    },
    setSidebarEquipmentList ({ commit }, equipmentList: EquipmentType[]): Promise<EquipmentType[]> {
      const sortedListByAlphabeticalOrder = _.sortBy(equipmentList, 'name')
      commit('SET_SIDEBAR_EQUIPMENT_LIST', sortedListByAlphabeticalOrder)
      return Promise.resolve(sortedListByAlphabeticalOrder)
    },
    async loadOneEquipment ({ commit }, equipmentId: number) : Promise<EquipmentType> {
      const res = await api.equipment.getOneBaseEquipmentControllerEquipment(equipmentId)
      return res.data
    },
    getEquipmentFromCache ({ commit, state }, equipmentId: number): EquipmentType {
      const cachedEquipment = state.cachedEquipment.filter(equipment => equipment.id === equipmentId)[0]
      return cachedEquipment
    },
    setSelectedEquipment ({ commit }, equipment: EquipmentType) {
      commit('SET_SELECTED_EQUIPMENT', equipment)
    },
    setEquipmentNavigationPath ({ commit }, path: EquipmentType[]): void {
      commit('SET_EQUIPMENT_NAVIGATION_PATH', path)
    },
    async goBackInEquipmentPath ({ commit, dispatch, state }) {
      if (VUE_APP_PLATFORM === 'web') {
        const currentlySelectedEquipment: EquipmentType = await dispatch('getEquipmentFromCache', state.selectedEquipment.id)

        if (currentlySelectedEquipment.parentEquipment) {
          dispatch('navigateToEquipmentInPathStore', currentlySelectedEquipment.parentEquipment)
        } else {
          commit('SET_SIDEBAR_EQUIPMENT_LIST', state.allEquipmentTree)
          commit('SET_SELECTED_EQUIPMENT')
        }
      } else {
        if (state.equipmentNavigationPath.length === 1) {
          dispatch('setSelectedEquipment', null)
          dispatch('setEquipmentNavigationPath', [])
          commit('SET_SIDEBAR_EQUIPMENT_LIST', state.allEquipmentTree)
        } else {
          const currentlySelectedEquipment: EquipmentType = await dispatch('getEquipmentFromCache', state.selectedEquipment.id)

          dispatch('navigateToEquipmentInPathStore', currentlySelectedEquipment.parentEquipment)
        }
      }
    },
    async generateFullPathFromCurrentlySelectedEquipment ({ state, dispatch }) {
      const equipmentPath: EquipmentType[] = []
      if (!state.selectedEquipment) return console.log('Can\'t generate path, reason: No selected equipment')
      equipmentPath.push(state.selectedEquipment)
      let parent: EquipmentType | undefined = state.selectedEquipment.parentEquipment
      while (parent) {
        const toAdd: EquipmentType = await dispatch('getEquipmentFromCache', parent.id)
        if (toAdd) equipmentPath.push(toAdd)
        parent = toAdd.parentEquipment
      }
      dispatch('setEquipmentNavigationPath', equipmentPath.reverse())
    },
    async createNewEquipment ({ commit, dispatch }, createEquipmentDto: CreateEquipmentDtoType): Promise<HttpResponse<EquipmentType, any>> {
      const res = await api.equipment.createOneBaseEquipmentControllerEquipment(createEquipmentDto)
      return res
    },
    async getEquipmentById ({ commit }, id: number): Promise<EquipmentType> {
      const res = await api.equipment.getOneBaseEquipmentControllerEquipment(id)
      console.log(res.data)
      return res.data
    },
    async deleteEquipment ({ commit }, equipmentId: number): Promise<boolean> {
      const res: HttpResponse<void, any> = await api.equipment.deleteOneBaseEquipmentControllerEquipment(equipmentId)
      return res.ok
    }
  },
  getters: {
    allEquipment (state): EquipmentType[] {
      return state.allEquipment
    },
    allEquipmentTree (state): EquipmentType[] {
      return state.allEquipmentTree
    },
    selectedEquipment (state): EquipmentType {
      return state.selectedEquipment
    },
    equipmentNavigationPath (state) {
      return state.equipmentNavigationPath
    },
    sidebarEquipmentList (state) {
      return state.sidebarEquipmentList
    }
  }
}
