























import { Component, Vue } from 'vue-property-decorator'
import TopOmniSearchBar from '@/components/SearchBar/TopOmniSearchBarDupe.vue'
import SidePanel from '@/components/SidePanel/SidePanel.vue'
import NavigationSidePanel from '@/components/SidePanel/NavigationSidePanel.vue'
import { Page } from '@/global/types'
import { createNamespacedHelpers } from 'vuex'
//! Use the "type" keyword to ONLY import the types (if you import the whole lib, it crashes the build whilst looking for some keyframe-animation library)
import type { confirm } from '@nativescript/core'

import store from '@/store'
import { getGravatarImageUrl, GravatarTypes } from '@/services/ui'
import ConfirmModal from '@/components/ConfirmModal.vue'

const { VUE_APP_PLATFORM } = process.env
const { mapGetters } = createNamespacedHelpers('topBar')

type TopBarOptions = {
  searchBar?: boolean
  pageName?: boolean
  profile?: boolean
}

  @Component({
    name: 'TheTopBar',
    components: {
      SidePanel,
      TopOmniSearchBar,
      NavigationSidePanel,
      ConfirmModal
    },
    computed: {
      ...mapGetters({
        topBarOptions: 'topBarOptions'
      })
    }
  })
export default class TheTopBar extends Vue {
  topBarOptions!: TopBarOptions

  navigationSidePanelOpen=false
  isAndroid = VUE_APP_PLATFORM === 'android'
  profilePictureUrl = 'https://www.gravatar.com/avatar/00000000000000000000000000000000?d=retro' // Gravatar null hash as the default
  confirmModalOpen = false

  showSidebarModal () {
    console.log('Showing side panel')
    this.$showModal(NavigationSidePanel, { animated: true, fullscreen: true })
  }

  mounted () : void {
    this.profilePictureUrl = getGravatarImageUrl(store.getters['auth/user'].email || 'example@example.com', GravatarTypes.retro).toString()
  }

  closeConfirmModal () {
    this.confirmModalOpen = false
  }

  displayLogoutModal () {
    this.confirmModalOpen = true
    if (!this.$isWeb) {
      confirm({
        title: 'Logout?',
        message: 'Are you sure you want to logout?',
        okButtonText: 'Logout',
        cancelButtonText: 'Cancel'
      }).then(async (clickedLogout) => {
        if (clickedLogout) {
          this.logout()
          // const deleted: boolean = await this.deleteEquipment(this.equipment.id)
          // if (deleted) {
          //   this.$notify({ text: 'Equipment deleted', type: AlertType.INFO })
          //   await this.loadAllEquipment()
          // } else {
          //   this.$notify({ text: 'Error while deleting equipment', type: AlertType.ERROR })
          // }
        }
      })
    }
  }

  logout () {
    store.dispatch('auth/logout')
    this.$goto('/login')
  }
}
