






































































import { Component, Vue } from 'vue-property-decorator'
import XCheckBox from '@/components/XCheckBox.vue'
import ErrorMessage from '@/components/ErrorMessage.vue'
import api from '@/services/api'
import store from '@/store/index'
import FormField from '@/components/FormField.vue'
import storage from '@/services/storage'
import auth from '@/services/auth'
import { AlertType } from '@/services/alerts/alert-options.type'
import { randomGreeting } from '@/services/ui'
const { VUE_APP_MODE } = process.env

@Component({
  name: 'Login',
  components: {
    XCheckBox,
    ErrorMessage,
    FormField
  },
  methods: {}
})
export default class Login extends Vue {
  // TODO: Login Error not implemented
  private loginErrorText?: string = undefined
  private username = ''
  private password = ''
  private validInputs = {}
  private showValidation = false

  get IS_WEB () {
    return VUE_APP_MODE !== 'native'
  }

  updateValidation (args) : void {
    this.validInputs[args.name] = !args.hasErrors
  }

  validationCheck () : boolean {
    var result = true
    for (const i in this.validInputs) {
      if (this.validInputs[i] === false) {
        result = false
        break
      }
    }
    return result
  }

  async login (): Promise<void> {
    if (!this.validationCheck()) {
      this.showValidation = true
    } else {
      this.loginErrorText = undefined
      try {
        var res = (await api.api.auth.authControllerLogin({ email: this.username, password: this.password }))
        var response = await res.json()
        try {
          // Needed for nativescript (to save cookies in native "local storage")
          if (!this.IS_WEB) {
            const cookies = auth.parseCookies(res.headers.get('set-cookie'))
            storage.set('auth', JSON.stringify(cookies))
          }

          //* Success!
          store.dispatch('auth/setUser', response.data.user)
          store.commit('auth/setExpiry', response.data.jwtExpiry)
          this.$router.push('/equipment-list', { clearHistory: true })

          this.$notify({ text: randomGreeting() + ', ' + store.getters['auth/user'].firstName, type: AlertType.SUCCESS })
        } catch (error) {
          console.log('Device Error - ', error)
          this.loginErrorText = 'Sorry, there was a device error:' + JSON.stringify(error)
          this.$notify({ text: this.loginErrorText, type: AlertType.ERROR })
        }
      } catch (err) {
        if (err instanceof Response) {
          const body = await err.json() as Record<string, any>

          let errorMessage = 'Sorry, something went wrong, please try again later'
          if (body?.statusCode === 401) errorMessage = 'Incorrect email or password'
          if (body?.data?.message) errorMessage = body.data.message

          this.loginErrorText = errorMessage
          this.$notify({ text: this.loginErrorText, type: AlertType.ERROR })
        } else {
          this.loginErrorText = 'Unknown Error'
          this.$notify({ text: this.loginErrorText, type: AlertType.ERROR })
        }
      }
    }
  }
}
