






















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Assignee from '@/components/Assignee.vue'
import UserList from '@/components/UserList.vue'
import FileUpload from '@/components/FileUpload/FileUpload.vue'
import FormField from '@/components/FormField.vue'
import FormEntry from '@/components/FormEntry.vue'
import File from '@/components/File.vue'
import store from '@/store/index'
import { createNamespacedHelpers } from 'vuex'
import { CreateEquipmentDtoType, EquipmentType, HttpResponse, UpdateEquipmentDtoType } from '@/api/SwaggerClient'
import SearchField from './SearchField.vue'
import { AlertType } from '@/services/alerts/alert-options.type'
import { api } from '@/services/api'
import ConfirmModal from '@/components/ConfirmModal.vue'
import { Switch } from '@nativescript/core'
//! Use the "type" keyword to ONLY import the types (if you import the whole lib, it crashes the build whilst looking for some keyframe-animation library)
import type { confirm } from '@nativescript/core'

const { mapActions, mapGetters } = createNamespacedHelpers('equipment')
const { mapGetters: mapGettersUi } = createNamespacedHelpers('ui')

  @Component({
    name: 'AddEquipmentForm',
    components: {
      Assignee,
      UserList,
      FileUpload,
      File,
      FormField,
      FormEntry,
      SearchField,
      ConfirmModal
    },
    methods: {
      ...mapActions({
        createNewEquipment: 'createNewEquipment',
        loadAllEquipment: 'loadAllEquipment'
      })
    },
    computed: {
      ...mapGetters({
        allEquipment: 'allEquipment',
        selectedEquipment: 'selectedEquipment'
      })
    }
  })
export default class AddEquipmentForm extends Vue {
    @Prop(Boolean) editMode?: boolean
    private hasParentEquipment = false
    private switchChecked = false
    private value: Record<string, any> = {}
    allEquipment!: EquipmentType[]
    private equipment: EquipmentType[] = []
    createNewEquipment!: (createNewEquipmentDto: CreateEquipmentDtoType) => Promise<HttpResponse<EquipmentType, any>>
    selectedEquipment!: EquipmentType
    private validInputs = {}
    private showValidation = false
    private dirty = false;
    private showConfirmModal = false

    loadAllEquipment!: () => Promise<void>
    foundEquipment = false
    startValue = {}
    errorMessage = 'Searching...'

    setDefaultParentEquipment () {
      this.value.parentEquipment = this.selectedEquipment
    }

    setDefaultParentEquipmentNative (args) {
      const sw = args.object as Switch
      const switchChecked = sw.checked
      if (!switchChecked) {
        this.value.parentEquipment = null
        return
      }
      this.value.parentEquipment = this.selectedEquipment
    }

    updateValue (key, value) : void {
      // console.log('Dirty to true')
      // Data stored in DB as null (e.g. a textbox left as empty during task creation) will fire updateValue when the modal is opened
      if (value !== null) this.dirty = true
      this.value = { ...this.value, [key]: value }
    }

    updateValidation (args) {
      this.validInputs[args.name] = !args.hasErrors
    }

    validationCheck () : boolean {
      var result = true
      for (const i in this.validInputs) {
        if (this.validInputs[i] === false) {
          result = false
          break
        }
      }
      return result
    }

    created () {
      this.equipment = this.allEquipment
    }

    @Watch('allEquipment')
    onAllEquipmentChanged () {
      this.equipment = this.allEquipment
    }

    takeId (object: Record<string, any>, defaultVal: any): any {
      try {
        if (typeof object?.id !== 'undefined') return object.id
        else return defaultVal
      } catch (error) {
        return defaultVal
      }
    }

    async mounted (): Promise<void> {
      try {
        if (this.editMode) {
          if (this.selectedEquipment.id !== 0) {
            this.foundEquipment = false
            const equipment = await this.$store.dispatch('equipment/loadOneEquipment', this.selectedEquipment.id)
            this.value = equipment as unknown as Record<string, unknown>
            this.foundEquipment = true
            if (this.selectedEquipment.parentEquipment) {
              this.hasParentEquipment = true
              this.switchChecked = true
            }
          } else {
            this.value = this.startValue || {}
            this.foundEquipment = true
          }
          this.equipment = store.getters['equipment/allEquipment']
        } else {
          this.foundEquipment = true
        }
      } catch (error) {
        console.log('Error getting equipment')
        console.log(error)
        this.errorMessage = 'There was an error... Please try again...'
      }
    }

    confirmCloseModal () {
      if (this.$isWeb) {
        this.showConfirmModal = true
      } else {
        confirm({
          title: 'Unsaved changes',
          message: 'Are you sure you want to close this modal?',
          okButtonText: 'Close',
          cancelButtonText: 'Stay'
        }).then(async (pressedClose) => {
          if (pressedClose) {
            this.closeModal()
          }
        })
      }
    }

    closeConfirmModal () {
      this.showConfirmModal = false
    }

    closeModal () {
      this.$emit('close')
      if (!this.$isWeb) {
        this.$modal.close()
      }
    }

    close () {
      if (this.dirty) {
        this.confirmCloseModal()
      } else {
        this.closeModal()
      }
    }

    async submitEquipment () {
      if (!this.validationCheck()) {
        this.showValidation = true
      } else {
        if (this.editMode) {
          const updatedEquipment = {
            name: this.value.name,
            serialNumber: this.value.serialNumber,
            parentEquipment: this.value?.parentEquipment || undefined
          } as UpdateEquipmentDtoType

          const res = await api.equipment.updateOneBaseEquipmentControllerEquipment(this.selectedEquipment.id, updatedEquipment)
          if (res.status === 200) {
            this.$notify({ text: 'Equipment updated', type: AlertType.SUCCESS })
            this.dirty = false
            // Refresh data
            await this.loadAllEquipment()
            this.close()
          } else {
            this.$notify({ text: 'Error while updating equipment', type: AlertType.ERROR })
          }
        } else {
          const data = this.value
          if (data.parentEquipment) {
            data.parentEquipment = data.parentEquipment.id
          }
          const newEquipment: CreateEquipmentDtoType = data
          const res = await this.createNewEquipment(newEquipment)
          if (res.status === 201) {
            this.$notify({ text: 'Equipment added', type: AlertType.SUCCESS })
            this.dirty = false
            // Refresh data
            await this.loadAllEquipment()
            this.close()
          } else {
            this.$notify({ text: 'Error, could not add equipment', type: AlertType.SUCCESS })
          }
        }
        // const newEquipment: CreateEquipmentDtoType = { ...data }
        // const res = await this.createNewEquipment(newEquipment)
        // console.log('RES :>>', res)
      }
    }
}
