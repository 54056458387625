

































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import TextFieldWithIcon from '@/components/TextFieldWithIcon.vue'
import SearchResult from '@/components/SearchResult.vue'
import XCheckBox from '@/components/XCheckBox.vue'

  @Component({
    name: 'FilterSidePanel',
    components: {
      TextFieldWithIcon,
      SearchResult,
      XCheckBox
    }
  })

export default class FilterSidePanel extends Vue {
  @Prop(String) private hint!:string;
  @Prop() private modalFilterOptions!:Record<string, any>
  searchTerm = '';
  showSearchResults = false;
  searchClearModeEnabled = false;
  private todo = false
  private ongoing = false
  private cancelled = false
  private done = false

  private sort = 'AZ'

  private showLogByAssignee = 'everyone'

  mounted () {
    if (!this.$isWeb && this.modalFilterOptions) {
      this.todo = this.modalFilterOptions?.status['to do']
      this.ongoing = this.modalFilterOptions?.status.ongoing
      this.cancelled = this.modalFilterOptions?.status.cancelled
      this.done = this.modalFilterOptions?.status.done
      this.sort = this.modalFilterOptions?.sort
      this.showLogByAssignee = this.modalFilterOptions?.showLogByAssignee
    }
  }

  applyFilters () {
    const filterOptions = {
      status: {
        'to do': this.todo,
        ongoing: this.ongoing,
        cancelled: this.cancelled,
        done: this.done
      },
      sort: this.sort,
      showLogByAssignee: this.showLogByAssignee
    }

    this.$emit('apply-filter', filterOptions)
    if (!this.$isWeb) {
      this.$modal.close(filterOptions)
    }
  }

  reset () {
    this.todo = false
    this.ongoing = false
    this.cancelled = false
    this.done = false
    this.sort = 'AZ'
    this.showLogByAssignee = 'everyone'

    this.applyFilters()
  }

  log (e: any): void {
    console.log(e)
  }

  search (e: Record<string, any>): void {
    console.log('Search for: ', e)
  }
}
