








import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import EquipmentPath from '@/views/EquipmentListPage/Sidebar/NavigationHeader/EquipmentPath.vue'
import { createNamespacedHelpers } from 'vuex'
import { EquipmentType } from '@/api/SwaggerClient'
const { mapGetters, mapActions } = createNamespacedHelpers('equipment')
  @Component({
    name: 'NavigationHeader',
    components: {
      EquipmentPath
    },
    methods: {
      ...mapActions({
        goBackInEquipmentPath: 'goBackInEquipmentPath'

      })
    },
    computed: {
      ...mapGetters({
        sidebarEquipmentList: 'sidebarEquipmentList',
        equipmentNavigationPath: 'equipmentNavigationPath',
        selectedEquipment: 'selectedEquipment'
      })
    }
  })
export default class NavigationHeader extends Vue {
  @Prop({ type: Boolean, required: false }) equipmentListExpanded
  sidebarEquipmentList!: EquipmentType[]
  equipmentNavigationPath!: EquipmentType[]

  selectedEquipment!: EquipmentType

  goBackInEquipmentPath!: () => void

  showEquipmentList = true

  collapse () {
    this.$emit('equipmentListExpanded', false)
  }

  expand () {
    this.$emit('equipmentListExpanded', true)
  }

  goBackToPreviousParent () {
    if (this.$isWeb) {
      if (this.equipmentNavigationPath.length > 1) {
        this.goBackInEquipmentPath()
      }
    } else {
      this.goBackInEquipmentPath()
    }
  }

  get selectedEquipmentName () {
    return this.selectedEquipment?.name || ''
  }
}
