import Vue from 'vue'
import VueRouter from 'vue-router'
import { routes } from './routes'
import store from '@/store/index'
import { createNamespacedHelpers } from 'vuex'
const { mapGetters } = createNamespacedHelpers('auth')

Vue.use(VueRouter)
export const webRouter = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

webRouter.beforeEach((to, from, next) => {
  if (to.path === '/') next('/login')
  try {
    // console.log('Gone to auth required route, user obj is: ', !!store.getters['auth/user'], ' and ', store.getters['auth/user'])

    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (!store.getters['auth/user']) {
        // Page requires auth, but no user info was found
        next({
          path: '/login',
          query: { redirect: to.fullPath }
        })
      } else next()
    } else {
      // console.log('store.getters/user :>> ', store.getters['auth/user'])
      // console.log(' to.meta?.loggedInRedirection :>> ', to.meta?.loggedInRedirection)
      if (store.getters['auth/loggedIn'] && to.meta?.loggedInRedirection) {
        // Page DOESN'T require auth - And an optional redirection for LOGGED IN clients was found
        next({ path: to.meta.loggedInRedirection })
      }
      next()
    }
  } catch (error) {
    console.log('Error insde router beforeEach')
    console.error(error)
  }
})
