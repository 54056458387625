import md5 from 'md5'
export const getStatusColour = function (text: string): string {
  // Should always have a default for "non-status" text
  switch (text) {
    case 'Ongoing':
      return 'text-blue-500'
    case 'Cancelled':
      return 'text-red-500'
    case 'Done':
      return 'text-green-600'
    default:
      return 'text-gray-700'
  }
}

export const formatBytes = function (bytes: number, decimals = 2): string {
  // From: https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const randomGreeting = function (): string {
  // Get a personalised message
  const hrs = new Date().getHours()
  let greet
  if (hrs < 12) greet = 'Good Morning'
  else if (hrs >= 12 && hrs < 18) greet = 'Good Afternoon'
  else if (hrs >= 18 && hrs <= 24) greet = 'Good Evening'
  else greet = 'Good Day'

  const welcomes = ['Welcome', 'Hello', 'Greetings', greet]

  return welcomes[Math.floor(Math.random() * welcomes.length)]
}

export enum GravatarTypes {
  NotFound = '404',
  mp = 'mp',
  identicon = 'identicon',
  monsterid = 'monsterid',
  wavatar = 'wavatar',
  retro = 'retro',
  robohash = 'robohash',
}

export const getGravatarImageUrl = function (email = '', type = GravatarTypes.identicon): string {
  //* Implementation from here: https://en.gravatar.com/site/implement
  const hash = md5(email.trim().toLowerCase())

  // const url = new URL('https://www.gravatar.com/avatar/' + hash)
  // const params = new URLSearchParams(url.search)
  // params.set('d', type.toString())

  // url.search = params.toString()

  // return url
  const url = `https://www.gravatar.com/avatar/${hash}?d=${type.toString()}`
  console.log('url :>> ', url)
  return url
}
