// import { isAndroid } from '@nativescript/core'

const cookieObjectToString = function (object: Record<string, unknown>): string {
  let cookieString = ''
  for (let [key, value] of Object.entries(object)) {
    if (key === 'refreshToken') {
      key = 'refresh_token'
    }
    cookieString = cookieString + key + '=' + value + '; '
  }
  cookieString = cookieString.slice(0, -2)

  return cookieString
}

const parseCookies = function (cookieString) {
  // if (isAndroid) {
  //   cookieArray = cookieString
  // } else {
  // }

  //* Each cookie (e.g. jwt, refresh) is formatted like jwt=token; Option=1; Secure, refresh=token; Option=2; Secure;
  //* This breaks each cookie into an array - still needs processing tho
  const cookieArray = cookieString.split(',')

  // jwt=token; Option=1; Secure;
  //*    ^ - take this out (from JWT and refresh)
  const jwtToken = cookieArray[0].split('; ')[0].split('=')[1]
  const refreshToken = cookieArray[1].split('; ')[0].split('=')[1]

  const cookies = { jwt: jwtToken, refreshToken: refreshToken }
  return cookies
}

const cookieArrayToObject = function (cookieStringArray: Array<string>): Record<string, unknown> {
  //* Format (from)
  //* jwt=tokenishere123; key=valuehere; finalkey=value3
  const cookies = {}
  if (!(cookieStringArray instanceof Array)) {
    const newArray = []
    newArray[0] = cookieStringArray
    cookieStringArray = newArray
  }

  cookieStringArray.forEach((cookie) => {
    const cookiesStrings = cookie.split('; ')[0]

    if (cookiesStrings.includes('=')) {
      const cookieStrings = cookiesStrings.split('=')
      cookies[cookieStrings[0]] = cookieStrings[1]
    }
  })

  return cookies
}

export default { cookieObjectToString, cookieArrayToObject, parseCookies }
export { cookieObjectToString, cookieArrayToObject, parseCookies }
