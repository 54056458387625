



















import { Component, Vue, Prop } from 'vue-property-decorator'
import TextFieldWithIcon from '@/components/TextFieldWithIcon.vue'
import { getGravatarImageUrl, GravatarTypes } from '@/services/ui'
import { AuthUserType } from '@/api/SwaggerClient'

  @Component({
    name: 'AssigneeResult',
    components: {
      TextFieldWithIcon
    }
  })
export default class AssigneeResult extends Vue {
  @Prop(String) private header!:string;
  @Prop(String) private tag!:string;
  @Prop(String) private body!:string;
  @Prop() private user!:AuthUserType;

  profilePictureUrl = getGravatarImageUrl(this.user.email || 'example@example.com', GravatarTypes.retro).toString()
}
