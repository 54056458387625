

























import { EquipmentType } from '@/api/SwaggerClient'
import AddEquipmentForm from '@/components/AddEquipmentForm.vue'
import { Component, Vue } from 'vue-property-decorator'
import { createNamespacedHelpers } from 'vuex'
const { mapGetters } = createNamespacedHelpers('equipment')
const { mapMutations } = createNamespacedHelpers('ui')
const { VUE_APP_MODE } = process.env

  @Component({
    name: 'Header',
    computed: {
      ...mapGetters({
        sidebarEquipmentList: 'sidebarEquipmentList'
      })
    },
    methods: {
      ...mapMutations({
        setCreateEquipmentModalDisplay: 'setCreateEquipmentModalDisplay'
      })
    }
  })
export default class Header extends Vue {
  sidebarEquipmentList!: EquipmentType[]
  setCreateEquipmentModalDisplay!: (val: boolean) => void
  private isWeb = VUE_APP_MODE === 'web'

  showCreateModal () {
    this.setCreateEquipmentModalDisplay(true)

    if (VUE_APP_MODE === 'native') {
      this.$showModal(AddEquipmentForm, { fullscreen: true })
    }
  }

  get equipmentCount (): number {
    return this.sidebarEquipmentList.length
  }
}
