import FormulateNSTextField from './FormulateNSTextField'

export default function FormulateNSTextFieldPlugin (formulateInstance) {
  formulateInstance.extend({
    components: {
      FormulateNSTextField
    },
    library: {
      nsTextField: {
        classification: 'text',
        component: 'FormulateNSTextField'
      }
    }
  })
}
