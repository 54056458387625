























import Vue from 'vue'

export default Vue.extend({
  props: {
    images: {
      type: Array,
      required: true
    },
    selectedImage: {
      type: Object,
      required: false,
      default: null
    }
  },
  methods: {
    close () {
      this.$modal.close()
    }
  },
  mounted () {
    console.log(this.images)
  }
})

