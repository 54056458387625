


































import { EquipmentType } from '@/api/SwaggerClient'
import { ScrollView } from '@nativescript/core'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { createNamespacedHelpers } from 'vuex'
const { mapGetters, mapActions } = createNamespacedHelpers('equipment')
const { VUE_APP_MODE } = process.env

@Component({
  name: 'EquipmentPath',
  components: {
  },
  methods: {
    ...mapActions({
      navigateToEquipmentInPathStore: 'navigateToEquipmentInPathStore',
      generateFullPathFromCurrentlySelectedEquipment: 'generateFullPathFromCurrentlySelectedEquipment'
    })
  },
  computed: {
    ...mapGetters({
      selectedEquipment: 'selectedEquipment',
      equipmentNavigationPath: 'equipmentNavigationPath'
    })
  }
})
export default class EquipmentPath extends Vue {
  navigateToEquipmentInPathStore!: (equipment: EquipmentType) => void
  generateFullPathFromCurrentlySelectedEquipment!: () => Promise<void>

  selectedEquipment!: EquipmentType
  equipmentNavigationPath!: EquipmentType[]
  isReady = false
  equipmentPathScrollView = null as unknown as ScrollView

  lastEquipmentInPath

  onLoad (args) {
    // Removed console.log because was causing errors
    // console.log('args :>> ', args)
    this.equipmentPathScrollView = args.object
  }

  scrollToEnd () {
    if (this.$isWeb) {
      // this.lastEquipmentInPath.scrollInToView()
    } else {
      // this.equipmentPathScrollView.scrollToHorizontalOffset(this.equipmentPathScrollView.scrollableWidth, true)
    }
  }

  // async created () {
  //   await this.generateFullPathFromCurrentlySelectedEquipment().then(() => {
  //   //   setTimeout(() => {
  //   //     this.scrollToEnd()
  //   //   }, 1000)
  //   })
  //   this.isReady = true
  // }

  async mounted () {
    if (this.$isWeb) {
      Vue.nextTick(() => {
        this.lastEquipmentInPath = document.getElementById('lastEquipmentInPath')
        console.log('this.lastEquipmentInPath :>> ', this.lastEquipmentInPath)
      })
    }

    this.isReady = true
  }

  isLastEquipmentInPath (equipmentId: number) {
    return this.selectedEquipment.id === equipmentId
  }

  @Watch('selectedEquipment', { immediate: true })
  async onSelectedEquipmentChanged () {
    await this.generateFullPathFromCurrentlySelectedEquipment().then(() => {
      setTimeout(() => {
        if (this.$isWeb) {
          if (this.lastEquipmentInPath) {
            this.scrollToEnd()
          } else {
            console.log('lastEquipmentInPath is null')
          }
        } else {
          this.scrollToEnd()
        }
      }, 100)
    })
  }
}
