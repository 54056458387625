import { render, staticRenderFns } from "./AssigneeResult.vue?vue&type=template&id=a3126788&scoped=true&"
import script from "./AssigneeResult.vue?vue&type=script&lang=ts&"
export * from "./AssigneeResult.vue?vue&type=script&lang=ts&"
import style0 from "./AssigneeResult.vue?vue&type=style&index=0&id=a3126788&web=true&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3126788",
  null
  
)

export default component.exports