












import { EquipmentType } from '@/api/SwaggerClient'
import { Component, Vue, Watch } from 'vue-property-decorator'
import AddEquipmentForm from '@/components/AddEquipmentForm.vue'
import Header from '@/views/EquipmentListPage/Sidebar/Header.vue'
import NavigationHeader from '@/views/EquipmentListPage/Sidebar/NavigationHeader/index.vue'
import EquipmentList from '@/views/EquipmentListPage/Sidebar/EquipmentList/index.vue'

import { createNamespacedHelpers } from 'vuex'
const { mapGetters, mapActions } = createNamespacedHelpers('equipment')
const { VUE_APP_MODE } = process.env

@Component({
  name: 'Sidebar',
  components: {
    Header,
    NavigationHeader,
    EquipmentList,
    AddEquipmentForm
  },
  methods: {
    ...mapActions({
      setSelectedEquipment: 'setSelectedEquipment',
      setSidebarEquipmentList: 'setSidebarEquipmentList'
    })
  },
  computed: {
    ...mapGetters({
      sidebarEquipmentList: 'sidebarEquipmentList',
      allEquipmentTree: 'allEquipmentTree'
    })
  }
})
export default class Sidebar extends Vue {
  setSelectedEquipment!: (equipment: EquipmentType) => void
  setSidebarEquipmentList!: (equipmentList: EquipmentType[]) => Promise<EquipmentType[]>

  sidebarEquipmentList!: EquipmentType[]
  allEquipmentTree!: EquipmentType[]

  equipmentList: EquipmentType[] = []

  private showEquipmentFormModal = false
  isReady = false
  equipmentListExpanded = true

  created () {
    if (this.$isWeb) {
      this.setSidebarEquipmentList(this.allEquipmentTree).then((list) => {
        this.setSelectedEquipment(list[0])
        this.equipmentList = list
        this.isReady = true
      })
    } else {
      this.setSidebarEquipmentList(this.allEquipmentTree).then((list) => {
        this.equipmentList = list
        this.isReady = true
      })
    }
  }

  setEquipmentListExpanded (expanded) {
    this.equipmentListExpanded = expanded
  }

  @Watch('sidebarEquipmentList')
  onSidebarEquipmentListChanged (newVal, oldVal) {
    if (this.sidebarEquipmentList.length) {
      this.equipmentList = newVal
    }
  }

  @Watch('allEquipmentTree')
  onAllEquipmentChanged () {
    this.equipmentList = this.allEquipmentTree
  }

  showAddEquipmentModal () {
    if (VUE_APP_MODE === 'web') {
      this.showEquipmentFormModal = true
    } else {
      this.$showModal(AddEquipmentForm, { fullscreen: true })
    }
  }
}
