















import api from '@/services/api'
import { Component, Vue } from 'vue-property-decorator'
  @Component({
    name: 'ForgotPassword',
    components: {
    }
  })
export default class ForgotPassword extends Vue {
  resetPassword (): void {
    this.$notify({ text: 'Not implemented', type: 'warning' })
    // api.api.auth.authControllerResetPassword()
  }
}
