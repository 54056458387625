import { AuthUserType } from '@/api/SwaggerClient'

export const ui = {
  namespaced: true,
  state: {
    selectedMaintenanceId: 0,
    selectedMaintenanceModal: false,
    selectedCreateMaintenanceId: 0,
    selectedCreateMaintenanceModal: false,
    selectedCreateEquipmentModal: false,
    selectedEquipmentId: 0,
    selectedEquipmentModal: false,
    recentlySearchedAssignees: [] as AuthUserType[]
  },
  actions: {

  },
  mutations: {
    setMaintenanceModalDisplay (state, val: boolean) {
      state.selectedMaintenanceModal = val
    },
    setCreateMaintenanceModalDisplay (state, val: boolean) {
      state.selectedCreateMaintenanceModal = val
    },
    setMaintenanceModalId (state, val: number) {
      state.selectedMaintenanceId = val
    },
    setCreateEquipmentModalDisplay (state, val: boolean) {
      state.selectedCreateEquipmentModal = val
    },
    setEquipmentModalDisplay (state, val: boolean) {
      state.selectedEquipmentModal = val
    },
    setEquipmentModalId (state, val: number) {
      state.selectedEquipmentId = val
    },
    setRecentlySearchedAssignees (state, val: AuthUserType[]) {
      state.recentlySearchedAssignees = val
    }
  },
  getters: {
    selectedEquipmentModal (state): void {
      return state.selectedEquipmentModal
    },
    selectedMaintenanceModal (state): void {
      return state.selectedMaintenanceModal
    },
    selectedCreateMaintenanceModal (state): void {
      return state.selectedCreateMaintenanceModal
    },
    selectedCreateEquipmentModal (state): void {
      return state.selectedCreateEquipmentModal
    },
    selectedMaintenanceId (state): void {
      return state.selectedMaintenanceId
    },
    selectedEquipmentId (state): void {
      return state.selectedEquipmentId
    },
    recentlySearchedAssignees (state): void {
      return state.recentlySearchedAssignees
    }
  }
}
