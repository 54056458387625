// import * as apiType from '../api/SwaggerClient'
import { Api } from '@/api/SwaggerClient'
import store from '@/store/index'
import storage from '@/services/storage'
import auth, { cookieObjectToString } from './auth'
const IS_ANDROID = process.env.VUE_APP_PLATFORM === 'android'
const { VUE_APP_MODE } = process.env
const IS_WEB = VUE_APP_MODE !== 'native'

let BASE_URL

if (process.env.NODE_ENV === 'development') {
  if (IS_ANDROID) {
    BASE_URL = 'http://10.0.2.2:3000'
  } else {
    BASE_URL = 'http://localhost:3000'
  }
} else {
  // BASE_URL = 'https://mining.primeboard.org/api'
  BASE_URL = process.env.VUE_APP_BACKEND_HOST
  if (!BASE_URL) { console.error('VUE_APP_BACKEND_HOST not defined - check .env or Docker parameters') }
}

console.log('BASE_URL :>> ', BASE_URL)

// axios.AxiosInstance
// const instance = axios.create({
//   baseURL: BASE_URL,
//   timeout: 5000
//   // headers: { 'X-Custom-Header': 'foobar' }
// })

const fetchWithHooks = async function (input: RequestInfo, init?: RequestInit | undefined) {
  //! Before request
  const { input: inputBeforeHook, init: initBeforeHook } = await beforeHook(input, init)
  return fetch(inputBeforeHook, initBeforeHook)
    .then(async response => {
      //! After request
      await afterHook(inputBeforeHook, initBeforeHook)
      return response
    })
}

const beforeHook = async function (input: RequestInfo, init?: RequestInit | undefined): Promise<{ input: RequestInfo; init?: RequestInit | undefined }> {
  //! Note: Awaits will block the UI - input is typically a string with the URL, and init is an object with: params, method, headers and so on

  // This is needed to blacklist the "auth not required" routes because it'll cause a cyclical loop
  // A "black listed route array"
  const isBlackListedRoute = ['auth/logout', 'auth/login', 'auth/register', 'auth/refresh-token'].some(route => String(input).endsWith(route))

  // console.log('API Request: ', input, ' - black list ', isBlackListedRoute)
  // console.log('API Request: ', init?.headers)

  if (init && init.headers) {
    // console.log(init.headers)
    //* Attempt to inject cookies (fail silently - cookies might not always exist in local storage)
    try {
      const cookieObject = JSON.parse(storage.get('auth'))
      const cookieString = cookieObjectToString(cookieObject || {})

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      init.headers.cookie = []
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      init.headers.cookie.push(cookieString)
    } catch (error) {
      console.error(error)
    }
  }

  if (!isBlackListedRoute) {
    if (store.getters['auth/expiry'] < (Date.now() / 1000)) {
      //* Refresh new one
      console.log('Token expired')
      await store.dispatch('auth/refreshToken', true)
    } else {
      console.log('Token not expired - Expires in: ' + (store.getters['auth/expiry'] - Date.now() / 1000) + ' seconds')
    }
  }
  // Return any edits (to be picked up by the fetchWithHooks)
  return { input, init }
}

const afterHook = async function (input: RequestInfo, init?: RequestInit | undefined) {
  //! Note: Awaits will block the UI - input is typically a string with the URL, and init is an object with: params, method, headers and so on
  // console.log('After Request Hook: ', input, init)
}

export const api = new Api({ baseUrl: BASE_URL, customFetch: fetchWithHooks, baseApiParams: { credentials: 'include' } })

// This SHOULDN'T be deleted! It is used by the pb-frontend-lib
async function apiGetRefreshToken (): Promise<{ content: Record<string, any> }> {
  const res = (await api.auth.authControllerRefreshtoken({}))
  const response = await res.json()

  // Needed for nativescript (to save cookies in native "local storage")
  if (!IS_WEB) {
    const cookies = auth.parseCookies(res.headers.get('set-cookie'))
    storage.set('auth', JSON.stringify(cookies))
    return { content: response }
  }

  return { content: response }
}

export { apiGetRefreshToken, BASE_URL }
export default { apiGetRefreshToken, api, BASE_URL }
