







































import { Component, Vue } from 'vue-property-decorator'
import SearchBar from '@/components/SearchBar/TopOmniSearchBar.vue'
import SidePanel from '@/components/SidePanel/SidePanel.vue'
import FilterSidePanel from '@/components/SidePanel/FilterSidePanel.vue'
import NavigationSidePanel from '@/components/SidePanel/NavigationSidePanel.vue'
import Table from '@/components/Table.vue'
import Select from '@/components/Select.vue'
import { getStatusColour } from '@/services/ui'

@Component({
  name: 'dev',
  components: {
    SearchBar,
    SidePanel,
    FilterSidePanel,
    NavigationSidePanel,
    Table,
    Select
  },
  data () {
    return {
      getStatusColour
    }
  },
  async mounted () {
    //
  }
})
export default class App extends Vue {
  filterSidePanelOpen = false;
  navigationSidePanelOpen = false;

  sortByOptions = [
    {
      name: 'Newest Maintenance Date First',
      data: 'asc'
    },
    {
      name: 'Oldest Maintenance Date First',
      data: 'dsc'
    },
    {
      name: 'Log Title (A-Z)',
      data: 'asc'
    },
    {
      name: 'Log Title (Z-A)',
      data: 'dsc'
    },
    {
      name: 'Highest Engine Hour First',
      data: 'asc'
    },
    {
      name: 'Lowest Engine Hour First',
      data: 'dsc'
    }
  ]

  sortBySelected (index: number): void {
    console.log(this.sortByOptions[index].name)
  }

  listOfLogs = [{
    name: 'Headlamp doesn\'t shine bright like a diamond',
    status: 'To Do',
    date: '12/12/2021',
    assignee: 'Raven Cluderay',
    hours: 1325
  }, {
    name: 'Engine leaking oil',
    status: 'Cancelled',
    date: '01/07/2021',
    assignee: 'Byrle Greenleaf',
    hours: 8334
  }, {
    name: 'Brakes too loose',
    status: 'Ongoing',
    date: '04/01/2022',
    assignee: 'Edsel Bygrove',
    hours: 3243
  }, {
    name: 'Headlamp not working',
    status: 'Done',
    date: '29/09/2021',
    assignee: 'Phip Danford',
    hours: 2892
  }]
}

