export interface LoginDtoType {
  /** Email */
  email: string;
  password: string;
}

export interface RegisterDtoType {
  /** Email */
  email: string;
  password: string;
  firstName?: string;
  lastName?: string;
}

export type RefreshTokenDtoType = object;

export interface GetManyEquipmentResponseDtoType {
  data: EquipmentType[];
  count: number;
  total: number;
  page: number;
  pageCount: number;
}

export interface GetManyMaintenanceLogResponseDtoType {
  data: MaintenanceLogType[];
  count: number;
  total: number;
  page: number;
  pageCount: number;
}

export interface GetManyAuthUserResponseDtoType {
  data: AuthUserType[];
  count: number;
  total: number;
  page: number;
  pageCount: number;
}

export interface AuthUserType {
  id?: number;
  firstName: string;
  lastName: string;
  email: string;

  /** @format date-time */
  date?: string;
  phoneNumber?: string;
  isTfaEnabled?: boolean;
}

export interface GetManyFileResponseDtoType {
  data: FileType[];
  count: number;
  total: number;
  page: number;
  pageCount: number;
}

export interface FileType {
  preSignedUrl?: string | null;
  s3Key?: string | null;
  name: string | null;
  sizeKb: number | null;
  maintenanceLog: MaintenanceLogType;
  id: number;

  /** @format date-time */
  deletedAt?: string;

  /** @format date-time */
  updatedAt?: string;

  /** @format date-time */
  createdAt?: string;
}

export interface MaintenanceLogType {
  title: string;
  status?: "Ongoing" | "To Do" | "Done" | "Cancelled";

  /** @format date-time */
  executionDate: string;
  duration?: number;
  engineHours?: string;
  description?: string;
  cost?: string;
  currency?: string;
  supplier?: string;
  createdBy: AuthUserType;
  equipment: EquipmentType;
  files?: FileType[];
  assignees?: AuthUserType[];
  id: number;

  /** @format date-time */
  deletedAt?: string;

  /** @format date-time */
  updatedAt?: string;

  /** @format date-time */
  createdAt?: string;
}

export interface EquipmentType {
  serialNumber: string;
  name: string;
  parentEquipment?: EquipmentType;
  childrenEquipment?: EquipmentType[];
  maintenanceLogs: MaintenanceLogType[];
  createdBy: AuthUserType;
  id: number;

  /** @format date-time */
  deletedAt?: string;

  /** @format date-time */
  updatedAt?: string;

  /** @format date-time */
  createdAt?: string;
}

export interface CreateEquipmentDtoType {
  parentEquipment?: number;
  serialNumber: string;
  name: string;
}

export type GetByIdType = object;

export interface UpdateEquipmentDtoType {
  maintenanceLogs?: GetByIdType[];
  serialNumber?: string;
  name?: string;
  parentEquipment?: number;
}

export interface CreateManyEquipmentDtoType {
  bulk: CreateEquipmentDtoType[];
}

export interface CreateFileDtoType {
  fileData: object;
  name: string | null;
  sizeKb: number | null;
  maintenanceLog: MaintenanceLogType;
}

export interface CreateManyFileDtoType {
  bulk: CreateFileDtoType[];
}

export interface PaginatedResponseDtoType {
  meta: { totalItems?: number; itemCount: number; itemsPerPage: number; totalPages?: number; currentPage: number };
  links: { first?: string; previous?: string; next?: string; last?: string };
}

export interface CreateMaintenanceLogDtoType {
  equipment: number;
  assignees?: GetByIdType[];
  title: string;
  status?: "Ongoing" | "To Do" | "Done" | "Cancelled";

  /** @format date-time */
  executionDate: string;
  duration?: number;
  engineHours?: string;
  description?: string;
  cost?: string;
  currency?: string;
  supplier?: string;
  files?: FileType[];
}

export interface CreateManyMaintenanceLogDtoType {
  bulk: CreateMaintenanceLogDtoType[];
}

export interface UpdateMaintenanceLogDtoType {
  title?: string;
  status?: "Ongoing" | "To Do" | "Done" | "Cancelled";

  /** @format date-time */
  executionDate?: string;
  duration?: number;
  engineHours?: string;
  description?: string;
  cost?: string;
  currency?: string;
  supplier?: string;
  files?: FileType[];
  equipment?: number;
  assignees?: GetByIdType[];
}

export interface CreateUserDtoType {
  id?: number;
  firstName?: string;
  lastName?: string;
  email?: string;

  /** @format date-time */
  date?: string;
  phoneNumber?: string;
  isTfaEnabled?: boolean;
}

export interface CreateManyAuthUserDtoType {
  bulk: CreateUserDtoType[];
}

export interface UpdateUserDtoType {
  id?: number;
  firstName?: string;
  lastName?: string;
  email?: string;

  /** @format date-time */
  date?: string;
  phoneNumber?: string;
  isTfaEnabled?: boolean;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  private addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  private addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  private mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  private createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
        ...(requestParams.headers || {}),
      },
      signal: cancelToken ? this.createAbortSignal(cancelToken) : void 0,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Mining App
 * @version 1.0
 * @contact
 *
 * Mining App Backend
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  notifications = {
    /**
     * No description
     *
     * @name NotificationsControllerAddRegistrationToken
     * @request POST:/notifications/registration-token
     * @response `201` `void`
     */
    notificationsControllerAddRegistrationToken: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/notifications/registration-token`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @name NotificationsControllerRemoveRegistrationToken
     * @request DELETE:/notifications/registration-token
     * @response `200` `void`
     */
    notificationsControllerRemoveRegistrationToken: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/notifications/registration-token`,
        method: "DELETE",
        ...params,
      }),
  };
  auth = {
    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerLogin
     * @summary Login as an AuthUser
     * @request POST:/auth/login
     * @response `201` `void`
     */
    authControllerLogin: (data: LoginDtoType, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/auth/login`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerResetPassword
     * @request POST:/auth/reset-password
     * @response `201` `void`
     */
    authControllerResetPassword: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/auth/reset-password`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerChangePassword
     * @request POST:/auth/change-password
     * @response `201` `void`
     */
    authControllerChangePassword: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/auth/change-password`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerChangePasswordLoggedIn
     * @request POST:/auth/change-password-logged-in
     * @response `201` `void`
     */
    authControllerChangePasswordLoggedIn: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/auth/change-password-logged-in`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerGetSelf
     * @request GET:/auth/self
     * @response `200` `void`
     */
    authControllerGetSelf: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/auth/self`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerEditSelf
     * @request PATCH:/auth/self
     * @response `200` `void`
     */
    authControllerEditSelf: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/auth/self`,
        method: "PATCH",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerRegister
     * @summary Register an AuthUser
     * @request POST:/auth/register
     * @response `201` `void`
     */
    authControllerRegister: (data: RegisterDtoType, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/auth/register`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerLogout
     * @request POST:/auth/logout
     * @response `201` `void`
     */
    authControllerLogout: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/auth/logout`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerLogincheck
     * @request POST:/auth/logincheck
     * @response `201` `void`
     */
    authControllerLogincheck: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/auth/logincheck`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerRefreshtoken
     * @summary Refresh token of an AuthUser
     * @request POST:/auth/refresh-token
     * @response `201` `void`
     */
    authControllerRefreshtoken: (data: RefreshTokenDtoType, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/auth/refresh-token`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerConfirmEmail
     * @request POST:/auth/confirm-email/{uuid}/{token}
     * @response `201` `void`
     */
    authControllerConfirmEmail: (uuid: string, token: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/auth/confirm-email/${uuid}/${token}`,
        method: "POST",
        ...params,
      }),
  };
  v2Fa = {
    /**
     * No description
     *
     * @tags Two Factor Authentication
     * @name TwoFactorAuthenticationControllerAuthenticate
     * @request POST:/2fa/authenticate
     * @response `200` `void`
     */
    twoFactorAuthenticationControllerAuthenticate: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/2fa/authenticate`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Two Factor Authentication
     * @name TwoFactorAuthenticationControllerEnableTfa
     * @request POST:/2fa/enable
     * @response `200` `void`
     */
    twoFactorAuthenticationControllerEnableTfa: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/2fa/enable`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Two Factor Authentication
     * @name TwoFactorAuthenticationControllerDisableTfa
     * @request POST:/2fa/disable
     * @response `200` `void`
     */
    twoFactorAuthenticationControllerDisableTfa: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/2fa/disable`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Two Factor Authentication
     * @name TwoFactorAuthenticationControllerGenerate
     * @request POST:/2fa/generate
     * @response `201` `void`
     */
    twoFactorAuthenticationControllerGenerate: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/2fa/generate`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Two Factor Authentication
     * @name TwoFactorAuthenticationControllerSendEmail
     * @request POST:/2fa/send-email
     * @response `201` `void`
     */
    twoFactorAuthenticationControllerSendEmail: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/2fa/send-email`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Two Factor Authentication
     * @name TwoFactorAuthenticationControllerSendSms
     * @request POST:/2fa/send-sms
     * @response `201` `void`
     */
    twoFactorAuthenticationControllerSendSms: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/2fa/send-sms`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Two Factor Authentication
     * @name TwoFactorAuthenticationControllerSendCall
     * @request POST:/2fa/send-call
     * @response `201` `void`
     */
    twoFactorAuthenticationControllerSendCall: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/2fa/send-call`,
        method: "POST",
        ...params,
      }),
  };
  equipment = {
    /**
     * No description
     *
     * @name EquipmentControllerSearch
     * @request GET:/equipment/search
     * @response `200` `(EquipmentType)[]`
     */
    equipmentControllerSearch: (query: { skip: number; count: number; term: string }, params: RequestParams = {}) =>
      this.request<EquipmentType[], any>({
        path: `/equipment/search`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateOneBaseEquipmentControllerEquipment
     * @summary Create a single Equipment
     * @request POST:/equipment
     * @response `200` `EquipmentType`
     * @response `201` `EquipmentType` Get create one base response
     */
    createOneBaseEquipmentControllerEquipment: (data: CreateEquipmentDtoType, params: RequestParams = {}) =>
      this.request<EquipmentType, any>({
        path: `/equipment`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetManyBaseEquipmentControllerEquipment
     * @summary Retrieve multiple Equipment
     * @request GET:/equipment
     * @response `200` `(GetManyEquipmentResponseDtoType | (EquipmentType)[])` Get many base response
     */
    getManyBaseEquipmentControllerEquipment: (
      query?: {
        fields?: string[];
        s?: string;
        filter?: string[];
        or?: string[];
        sort?: string[];
        join?: string[];
        limit?: number;
        offset?: number;
        page?: number;
        cache?: number;
        include_deleted?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetManyEquipmentResponseDtoType | EquipmentType[], any>({
        path: `/equipment`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateOneBaseEquipmentControllerEquipment
     * @summary Update a single Equipment
     * @request PATCH:/equipment/{id}
     * @response `200` `EquipmentType`
     */
    updateOneBaseEquipmentControllerEquipment: (id: number, data: UpdateEquipmentDtoType, params: RequestParams = {}) =>
      this.request<EquipmentType, any>({
        path: `/equipment/${id}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetOneBaseEquipmentControllerEquipment
     * @summary Retrieve a single Equipment
     * @request GET:/equipment/{id}
     * @response `200` `EquipmentType`
     */
    getOneBaseEquipmentControllerEquipment: (
      id: number,
      query?: { fields?: string[]; join?: string[]; cache?: number; include_deleted?: number },
      params: RequestParams = {},
    ) =>
      this.request<EquipmentType, any>({
        path: `/equipment/${id}`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ReplaceOneBaseEquipmentControllerEquipment
     * @summary Replace a single Equipment
     * @request PUT:/equipment/{id}
     * @response `200` `EquipmentType` Response
     */
    replaceOneBaseEquipmentControllerEquipment: (id: number, data: EquipmentType, params: RequestParams = {}) =>
      this.request<EquipmentType, any>({
        path: `/equipment/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name DeleteOneBaseEquipmentControllerEquipment
     * @summary Delete a single Equipment
     * @request DELETE:/equipment/{id}
     * @response `200` `void` Delete one base response
     */
    deleteOneBaseEquipmentControllerEquipment: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/equipment/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @name EquipmentControllerGetAllEquipmentAsTree
     * @request GET:/equipment/tree
     * @response `200` `(EquipmentType)[]`
     */
    equipmentControllerGetAllEquipmentAsTree: (params: RequestParams = {}) =>
      this.request<EquipmentType[], any>({
        path: `/equipment/tree`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateManyBaseEquipmentControllerEquipment
     * @summary Create multiple Equipment
     * @request POST:/equipment/bulk
     * @response `201` `(EquipmentType)[]` Get create many base response
     */
    createManyBaseEquipmentControllerEquipment: (data: CreateManyEquipmentDtoType, params: RequestParams = {}) =>
      this.request<EquipmentType[], any>({
        path: `/equipment/bulk`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name RecoverOneBaseEquipmentControllerEquipment
     * @summary Recover one Equipment
     * @request PATCH:/equipment/{id}/recover
     * @response `200` `void` Recover one base response
     */
    recoverOneBaseEquipmentControllerEquipment: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/equipment/${id}/recover`,
        method: "PATCH",
        ...params,
      }),
  };
  undefined = {
    /**
     * No description
     *
     * @name CreateOneBaseFileControllerFile
     * @summary Create a single File
     * @request UNDEFINED:undefined
     * @response `201` `FileType` Get create one base response
     */
    createOneBaseFileControllerFile: (data: CreateFileDtoType, params: RequestParams = {}) =>
      this.request<FileType, any>({
        path: `undefined`,
        method: "UNDEFINED",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  files = {
    /**
     * No description
     *
     * @name CreateOneBaseFileControllerFile
     * @summary Create a single File
     * @request POST:/files
     * @response `200` `FileType`
     * @response `201` `FileType` Get create one base response
     */
    createOneBaseFileControllerFile: (data: { maintenanceLog?: string; file?: File }, params: RequestParams = {}) =>
      this.request<FileType, any>({
        path: `/files`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetManyBaseFileControllerFile
     * @summary Retrieve multiple Files
     * @request GET:/files
     * @response `200` `(GetManyFileResponseDtoType | (FileType)[])` Get many base response
     */
    getManyBaseFileControllerFile: (
      query?: {
        fields?: string[];
        s?: string;
        filter?: string[];
        or?: string[];
        sort?: string[];
        join?: string[];
        limit?: number;
        offset?: number;
        page?: number;
        cache?: number;
        include_deleted?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetManyFileResponseDtoType | FileType[], any>({
        path: `/files`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetOneBaseFileControllerFile
     * @summary Retrieve a single File
     * @request GET:/files/{id}
     * @response `200` `FileType` Get one base response
     */
    getOneBaseFileControllerFile: (
      id: number,
      query?: { fields?: string[]; join?: string[]; cache?: number; include_deleted?: number },
      params: RequestParams = {},
    ) =>
      this.request<FileType, any>({
        path: `/files/${id}`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateOneBaseFileControllerFile
     * @summary Update a single File
     * @request PATCH:/files/{id}
     * @response `200` `FileType` Response
     */
    updateOneBaseFileControllerFile: (id: number, data: FileType, params: RequestParams = {}) =>
      this.request<FileType, any>({
        path: `/files/${id}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ReplaceOneBaseFileControllerFile
     * @summary Replace a single File
     * @request PUT:/files/{id}
     * @response `200` `FileType` Response
     */
    replaceOneBaseFileControllerFile: (id: number, data: FileType, params: RequestParams = {}) =>
      this.request<FileType, any>({
        path: `/files/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name DeleteOneBaseFileControllerFile
     * @summary Delete a single File
     * @request DELETE:/files/{id}
     * @response `200` `void` Delete one base response
     */
    deleteOneBaseFileControllerFile: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/files/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateManyBaseFileControllerFile
     * @summary Create multiple Files
     * @request POST:/files/bulk
     * @response `201` `(FileType)[]` Get create many base response
     */
    createManyBaseFileControllerFile: (data: CreateManyFileDtoType, params: RequestParams = {}) =>
      this.request<FileType[], any>({
        path: `/files/bulk`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name RecoverOneBaseFileControllerFile
     * @summary Recover one File
     * @request PATCH:/files/{id}/recover
     * @response `200` `void` Recover one base response
     */
    recoverOneBaseFileControllerFile: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/files/${id}/recover`,
        method: "PATCH",
        ...params,
      }),
  };
  maintenanceLog = {
    /**
     * No description
     *
     * @name MaintenanceLogControllerSearch
     * @request GET:/maintenance-log/search
     * @response `200` `(MaintenanceLogType)[]`
     */
    maintenanceLogControllerSearch: (
      query: { skip: number; count: number; term: string },
      params: RequestParams = {},
    ) =>
      this.request<MaintenanceLogType[], any>({
        path: `/maintenance-log/search`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name MaintenanceLogControllerGetEquipmentNestedLogs
     * @request GET:/maintenance-log/equipment/{equipmentId}/nested-logs
     * @response `200` `(PaginatedResponseDtoType & { items?: (MaintenanceLogType)[] })`
     */
    maintenanceLogControllerGetEquipmentNestedLogs: (
      equipmentId: number,
      query?: {
        limit?: number;
        page?: number;
        sortBy?: "newest" | "oldest" | "title_asc" | "title_desc" | "engine_hours_highest" | "engine_hours_lowest";
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginatedResponseDtoType & { items?: MaintenanceLogType[] }, any>({
        path: `/maintenance-log/equipment/${equipmentId}/nested-logs`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetOneBaseMaintenanceLogControllerMaintenanceLog
     * @summary Retrieve a single MaintenanceLog
     * @request GET:/maintenance-log/{id}
     * @response `200` `MaintenanceLogType` Get one base response
     */
    getOneBaseMaintenanceLogControllerMaintenanceLog: (
      id: number,
      query?: { fields?: string[]; join?: string[]; cache?: number; include_deleted?: number },
      params: RequestParams = {},
    ) =>
      this.request<MaintenanceLogType, any>({
        path: `/maintenance-log/${id}`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateOneBaseMaintenanceLogControllerMaintenanceLog
     * @summary Update a single MaintenanceLog
     * @request PATCH:/maintenance-log/{id}
     * @response `200` `MaintenanceLogType` Response
     */
    updateOneBaseMaintenanceLogControllerMaintenanceLog: (
      id: number,
      data: UpdateMaintenanceLogDtoType,
      params: RequestParams = {},
    ) =>
      this.request<MaintenanceLogType, any>({
        path: `/maintenance-log/${id}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ReplaceOneBaseMaintenanceLogControllerMaintenanceLog
     * @summary Replace a single MaintenanceLog
     * @request PUT:/maintenance-log/{id}
     * @response `200` `MaintenanceLogType` Response
     */
    replaceOneBaseMaintenanceLogControllerMaintenanceLog: (
      id: number,
      data: MaintenanceLogType,
      params: RequestParams = {},
    ) =>
      this.request<MaintenanceLogType, any>({
        path: `/maintenance-log/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name DeleteOneBaseMaintenanceLogControllerMaintenanceLog
     * @summary Delete a single MaintenanceLog
     * @request DELETE:/maintenance-log/{id}
     * @response `200` `void` Delete one base response
     */
    deleteOneBaseMaintenanceLogControllerMaintenanceLog: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/maintenance-log/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetManyBaseMaintenanceLogControllerMaintenanceLog
     * @summary Retrieve multiple MaintenanceLogs
     * @request GET:/maintenance-log
     * @response `200` `(GetManyMaintenanceLogResponseDtoType | (MaintenanceLogType)[])` Get many base response
     */
    getManyBaseMaintenanceLogControllerMaintenanceLog: (
      query?: {
        fields?: string[];
        s?: string;
        filter?: string[];
        or?: string[];
        sort?: string[];
        join?: string[];
        limit?: number;
        offset?: number;
        page?: number;
        cache?: number;
        include_deleted?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetManyMaintenanceLogResponseDtoType | MaintenanceLogType[], any>({
        path: `/maintenance-log`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateOneBaseMaintenanceLogControllerMaintenanceLog
     * @summary Create a single MaintenanceLog
     * @request POST:/maintenance-log
     * @response `201` `MaintenanceLogType` Get create one base response
     */
    createOneBaseMaintenanceLogControllerMaintenanceLog: (
      data: CreateMaintenanceLogDtoType,
      params: RequestParams = {},
    ) =>
      this.request<MaintenanceLogType, any>({
        path: `/maintenance-log`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateManyBaseMaintenanceLogControllerMaintenanceLog
     * @summary Create multiple MaintenanceLogs
     * @request POST:/maintenance-log/bulk
     * @response `201` `(MaintenanceLogType)[]` Get create many base response
     */
    createManyBaseMaintenanceLogControllerMaintenanceLog: (
      data: CreateManyMaintenanceLogDtoType,
      params: RequestParams = {},
    ) =>
      this.request<MaintenanceLogType[], any>({
        path: `/maintenance-log/bulk`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name RecoverOneBaseMaintenanceLogControllerMaintenanceLog
     * @summary Recover one MaintenanceLog
     * @request PATCH:/maintenance-log/{id}/recover
     * @response `200` `void` Recover one base response
     */
    recoverOneBaseMaintenanceLogControllerMaintenanceLog: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/maintenance-log/${id}/recover`,
        method: "PATCH",
        ...params,
      }),
  };
  users = {
    /**
     * No description
     *
     * @tags Users
     * @name UsersControllerSearch
     * @request GET:/users/search
     * @response `200` `void`
     */
    usersControllerSearch: (query: { term: string }, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/users/search`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name GetOneBaseUsersControllerAuthUser
     * @summary Retrieve a single AuthUser
     * @request GET:/users/{id}
     * @response `200` `AuthUserType` Get one base response
     */
    getOneBaseUsersControllerAuthUser: (
      id: number,
      query?: { fields?: string[]; join?: string[]; cache?: number; include_deleted?: number },
      params: RequestParams = {},
    ) =>
      this.request<AuthUserType, any>({
        path: `/users/${id}`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UpdateOneBaseUsersControllerAuthUser
     * @summary Update a single AuthUser
     * @request PATCH:/users/{id}
     * @response `200` `AuthUserType` Response
     */
    updateOneBaseUsersControllerAuthUser: (id: number, data: UpdateUserDtoType, params: RequestParams = {}) =>
      this.request<AuthUserType, any>({
        path: `/users/${id}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name ReplaceOneBaseUsersControllerAuthUser
     * @summary Replace a single AuthUser
     * @request PUT:/users/{id}
     * @response `200` `AuthUserType` Response
     */
    replaceOneBaseUsersControllerAuthUser: (id: number, data: AuthUserType, params: RequestParams = {}) =>
      this.request<AuthUserType, any>({
        path: `/users/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name DeleteOneBaseUsersControllerAuthUser
     * @summary Delete a single AuthUser
     * @request DELETE:/users/{id}
     * @response `200` `void` Delete one base response
     */
    deleteOneBaseUsersControllerAuthUser: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/users/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name GetManyBaseUsersControllerAuthUser
     * @summary Retrieve multiple AuthUsers
     * @request GET:/users
     * @response `200` `(GetManyAuthUserResponseDtoType | (AuthUserType)[])` Get many base response
     */
    getManyBaseUsersControllerAuthUser: (
      query?: {
        fields?: string[];
        s?: string;
        filter?: string[];
        or?: string[];
        sort?: string[];
        join?: string[];
        limit?: number;
        offset?: number;
        page?: number;
        cache?: number;
        include_deleted?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetManyAuthUserResponseDtoType | AuthUserType[], any>({
        path: `/users`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name CreateOneBaseUsersControllerAuthUser
     * @summary Create a single AuthUser
     * @request POST:/users
     * @response `201` `AuthUserType` Get create one base response
     */
    createOneBaseUsersControllerAuthUser: (data: CreateUserDtoType, params: RequestParams = {}) =>
      this.request<AuthUserType, any>({
        path: `/users`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name CreateManyBaseUsersControllerAuthUser
     * @summary Create multiple AuthUsers
     * @request POST:/users/bulk
     * @response `201` `(AuthUserType)[]` Get create many base response
     */
    createManyBaseUsersControllerAuthUser: (data: CreateManyAuthUserDtoType, params: RequestParams = {}) =>
      this.request<AuthUserType[], any>({
        path: `/users/bulk`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name RecoverOneBaseUsersControllerAuthUser
     * @summary Recover one AuthUser
     * @request PATCH:/users/{id}/recover
     * @response `200` `void` Recover one base response
     */
    recoverOneBaseUsersControllerAuthUser: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/users/${id}/recover`,
        method: "PATCH",
        ...params,
      }),
  };
}
