

















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'ConfirmModal'
})
export default class ConfirmModal extends Vue {
    @Prop(String) header
    @Prop(String) body

    confirmed () : void {
      this.$emit('confirmed')
      this.$emit('close')
    }
}
