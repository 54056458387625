
















import { FileType } from '@/api/SwaggerClient'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { formatBytes } from '@/services/ui'
import { AlertType } from '@/services/alerts/alert-options.type'
  @Component({
    name: 'File',
    data () {
      return {
        formatBytes
      }
    },
    methods: {
      checkFileType (name) {
        const extension = name.split('.')[1]
        if (['jpeg', 'jpg', 'png'].includes(extension)) {
          return true
        } else return false
      },
      download (link) {
        if (link) { window.open(link) } else this.$notify({ text: 'File not found', type: AlertType.ERROR })
      }
    }
  })
export default class File extends Vue {
  @Prop() private file!:FileType;

  deleteItem () : void {
    this.$emit('deleteItem')
  }
}
