


















import { Component, Vue, Prop } from 'vue-property-decorator'

  @Component({
    name: 'TextFieldWithIcon',
    components: {
    }
  })
export default class TextFieldWithIcon extends Vue {
  @Prop(String) private hint!:string;
  @Prop(String) private fasCode!:string;
  @Prop(Boolean) private clearMode!:boolean;
  value = '';

  clear () {
    this.value = ''
    this.$emit('termUpdated', this.value)
  }
}
