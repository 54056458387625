













import { Component, Vue } from 'vue-property-decorator'
import LoginPage from '~/views/LoginPage.vue'
import TheTopBar from '@/components/TheTopBar.vue'
import { routes } from '@/services/router/routes'
import EquipmentLogForm from '@/components/EquipmentLogForm.vue'
import AddEquipmentForm from '@/components/AddEquipmentForm.vue'
import SidePanel from '@/components/SidePanel/SidePanel.vue'
import NavigationSidePanel from '@/components/SidePanel/NavigationSidePanel.vue'
import EquipmentListPage from '@/views/EquipmentListPage/index.vue'
import { createNamespacedHelpers } from 'vuex'
import store from './store'
import { randomGreeting } from './services/ui'
import { AlertType } from './services/alerts/alert-options.type'

const { mapGetters, mapMutations } = createNamespacedHelpers('ui')
const { VUE_APP_MODE, VUE_APP_PLATFORM } = process.env
Vue.component('TheTopBar', TheTopBar)

Vue.directive('verticalCenter', {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  bind (el, binding, vnode) {
    if (VUE_APP_PLATFORM === 'android') {
      el.nativeView.on('loaded', (args) => {
        try {
          args.object.nativeView.setGravity(17)
        } catch (error) {
          // Removing this try/catch will result in crashes
          console.warn('v-verticalCenter is likely being used on a component that does NOT support it - I can only say it supports Label. Please check error below:')
          console.error(error)
        }
      })
    }
  }
})

@Component({
  name: 'home',
  components: {
    LoginPage,
    SidePanel,
    NavigationSidePanel,
    EquipmentLogForm,
    AddEquipmentForm,
    EquipmentListPage
  },
  data () {
    return {
      routes
    }
  },
  computed: {
    ...mapGetters({
      selectedEquipmentModal: 'selectedEquipmentModal',
      selectedCreateEquipmentModal: 'selectedCreateEquipmentModal',
      selectedMaintenanceModal: 'selectedMaintenanceModal',
      selectedCreateMaintenanceModal: 'selectedCreateMaintenanceModal'
    })
  },
  methods: {
    ...mapMutations({
      setEquipmentModalDisplay: 'setEquipmentModalDisplay',
      setCreateEquipmentModalDisplay: 'setCreateEquipmentModalDisplay',
      setMaintenanceModalDisplay: 'setMaintenanceModalDisplay',
      setCreateMaintenanceModalDisplay: 'setCreateMaintenanceModalDisplay'
    })
  },
  async mounted () {
    await store.dispatch('auth/refreshToken', true)

    if (store.getters['auth/user']) {
      this.$notify({ text: randomGreeting() + ', ' + store.getters['auth/user'].firstName, type: AlertType.SUCCESS })
      this.$router.push('/equipment-list', { clearHistory: true })
    }

    if (this.$route.query && this.$route.query.redirect) this.$goto(this.$route.query.redirect)
    // await store.dispatch('auth/refreshToken', true)
    // Now we know a user is logged in, make a route change to desired location
    // if (this.$router.currentRoute.query.redirect) this.$router.push(String(this.$router.currentRoute.query.redirect))
  }
})
export default class App extends Vue {
    private navbarTitle = 'Mining-App';
    private msg = `Mode=${VUE_APP_MODE} and Platform=${VUE_APP_PLATFORM}`;
    get loggedIn (): boolean {
      return store.getters['auth/loggedIn']
    }
}
