








import { Component, Vue, Prop } from 'vue-property-decorator'
  @Component({
    name: 'SelectModal',
    components: {
    }
  })
export default class SelectModal extends Vue {
  @Prop(String) title
  @Prop(Array) list
  private selectedIndex = 0

  select (): void {
    console.log('Closing select modal, returning item index: ', this.selectedIndex)
    this.$modal.close(this.selectedIndex)
  }
}
