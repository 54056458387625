<template>
  <TextField
    :class="`formulate-input-element formulate-input-element--${context.type} ${context.attributes.textFieldStyle}`"
    :data-type="context.type"
    v-model="context.model"
    v-bind="context.attributes"
    :keyboardType="context.attributes.keyboardType || 'text'"
    :hint="context.attributes.placeholder"
    :secure="context.attributes.secure"
    label="label"
  @close="onClose"/>
</template>

<script>
export default {
  props: {
    context: {
      type: Object,
      required: true
    }
  },
  methods: {
    onClose: function () {
      this.context.blurHandler()
    }
  }
}
</script>
