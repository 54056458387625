export const topBar = {
  namespaced: true,
  state: {
    topBarOptions: { searchBar: true, profile: true, pageName: true }
  },
  mutations: {
    SET_TOPBAR_OPTIONS (state, topBarOptions) {
      state.topBarOptions = topBarOptions
    }
  },
  actions: {
    setTopBarOptions ({ commit }, topBarOptions) {
      commit('SET_TOPBAR_OPTIONS', topBarOptions)
    }
  },
  getters: {
    topBarOptions (state) {
      return state.topBarOptions
    }
  }
}

// How to use: -> add these two lines in a created of a page
// const topBarOptions = { searchBar: true, profile: true, pageName: true }
//     this.$store.dispatch('topBar/setTopBarOptions', topBarOptions)
