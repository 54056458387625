

















import { Component, Vue, Prop } from 'vue-property-decorator'
import AssigneeSearchBar from './SearchBar/AssigneeSearchBarDupe.vue'

const { VUE_APP_MODE } = process.env

  @Component({
    name: 'UserList',
    components: {
      AssigneeSearchBar
    }
  })
export default class UserList extends Vue {
  @Prop(String) private user!:string;
  private isWeb = false
  private selectedUser

  mounted () : void {
    if (VUE_APP_MODE === 'web') {
      this.isWeb = true
    }
  }

  addUser (selectedUser: Record<string, any>) : void {
    console.log('selectedUser:', selectedUser)
    if (selectedUser) {
      this.$emit('add-user', selectedUser)
    }
  }
}
