import { render, staticRenderFns } from "./NavigationSidePanel.vue?vue&type=template&id=492d394a&scoped=true&web=true&"
import script from "./NavigationSidePanel.vue?vue&type=script&lang=ts&"
export * from "./NavigationSidePanel.vue?vue&type=script&lang=ts&"
import style0 from "./NavigationSidePanel.vue?vue&type=style&index=0&id=492d394a&web=true&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "492d394a",
  null
  
)

export default component.exports