






















import { Component, Vue, Prop } from 'vue-property-decorator'
import TextFieldWithIcon from '@/components/TextFieldWithIcon.vue'
import { getStatusColour } from '@/services/ui'

  @Component({
    name: 'SearchResult',
    components: {
      TextFieldWithIcon
    },
    data () {
      return {
        getStatusColour
      }
    }
  })
export default class SearchResult extends Vue {
  @Prop(String) private header!:string;
  @Prop(String) private tag!:string;
  @Prop(String) private body!:string;
  @Prop(String) private fasCode!:string;
}
