interface Page {
  name?: string;
  route: string;
  icon?: string
}

export enum MaintenanceLogSortOptions {
  NEWEST = 'newest',
  OLDEST = 'oldest',
  TITLE_ASC = 'title_asc',
  TITLE_DESC = 'title_desc',
  ENGINE_HOURS_HIGHEST = 'engine_hours_highest',
  ENGINE_HOURS_LOWEST = 'engine_hours_lowest',
}

interface PaginatedNestedEquipmentLogsRequest {
  equipmentId: number;
  page?: number | undefined
  limit?: number | undefined
  sortBy?: MaintenanceLogSortOptions
}

export { Page, PaginatedNestedEquipmentLogsRequest }
